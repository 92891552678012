import moment from "moment";
import axionIns from "../axios";

const getRevenueDetail = async () => {
    return axionIns
        .get(`admin/revenuedashboard`)
        .then((response) => {
            return response.data;
        });
};

const getFilteredRevenueDetail = async (type) => {
    return axionIns
        .get(`admin/filterrevenue?type=${type}`)
        .then((response) => {
            return response.data;
        });
};

export default {
    getRevenueDetail,
    getFilteredRevenueDetail
}