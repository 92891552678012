import moment from "moment";
import axionIns from "../axios";

const getAllJobs = async (page, limit, status, date) => {
    return axionIns
        .get(`admin/jobs?page=${page ?? ''}&limit=${limit ?? ''}&status=${status ?? ''}&fromDate=${date?.length ? moment(date[0]).format('YYYY-MM-DD') : ''}&toDate=${date?.length ? moment(date[1]).format('YYYY-MM-DD') : ''}`)
        .then((response) => {
            return response.data;
        });
}

export default {
    getAllJobs,
};
