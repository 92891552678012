import { GET_ALL_JOBS } from "../Action/type";

const initialState = {
    allJobs: [],
    count: 0,
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_JOBS:
            return {
                ...state,
                allJobs: payload?.jobs,
                count: payload?.count,
            };

        default:
            return state;
    }
}
