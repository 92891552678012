import userService from "../Services/userService";
import { showToast } from "./toast";
import { GET_JOB_DETAIL, GET_NOTIFICATION_SETTING, GET_PAST_JOBS, GET_SINGLE_USER, GET_TRANSACTION_HISTORY, GET_USER, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getUserAction = (page, limit, search) => async (dispatch) => {
  return userService.getUser(page, limit, search).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_USER, payload: resp.data });
    } else {
      //   dispatch(showToast(resp?.data, "error"));
    }
  });
};

export const getNotificationSettingList = () => async (dispatch) => {
  return userService.getNotifiList().then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_NOTIFICATION_SETTING, payload: resp.data });
    } else {
      //   dispatch(showToast(resp?.data, "error"));
    }
  });
};

export const getSingleUserDetail = (id) => async (dispatch) => {
  return userService.getSingleUser(id).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_SINGLE_USER, payload: resp.data });
    } else {
      //   dispatch(showToast(resp?.data, "error"));
    }
  });
};


export const getPastJobsSingleUser = (currentPage, recordsPerPage, status, id) => async (dispatch) => {
  return userService.getPastJobs(currentPage, recordsPerPage, status, id).then((resp) => {

    if (resp.status) {
      dispatch({ type: GET_PAST_JOBS, payload: resp.data });
    } else {
      //   dispatch(showToast(resp?.data, "error"));
    }
  });
};

export const getTransactionHistory = (currentPage, recordsPerPage, date, status, type, id) => async (dispatch) => {
  return userService.getTransHistory(currentPage, recordsPerPage, date, status, type, id).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_TRANSACTION_HISTORY, payload: resp.data });
    } else {
      //   dispatch(showToast(resp?.data, "error"));
    }
  });
};


export const getSingleJobDetail = (id) => async (dispatch) => {
  dispatch(startLoaderAction());
  return userService.getJobDetail(id).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_JOB_DETAIL, payload: resp.data });
    } else {
      //   dispatch(showToast(resp?.data, "error"));
    }
    dispatch(stopLoaderAction());
  });
};

export const deactivateUserAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await userService.deActivateUser(payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const activateUserAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await userService.activateUser(payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const updateUserPasswordAction = (id, payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await userService.updatePassword(id, payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const updateUserProfile = (id, payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await userService.updateProfile(id, payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};
