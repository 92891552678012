import { GET_CATEGORY, GET_QUESTION } from "../Action/type";

const initialState = {
    questionsList: [],
    count: 0,
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    console.log(payload?.questions);

    switch (type) {
        case GET_QUESTION:
            return {
                ...state,
                questionsList: payload?.questions,
                count: payload?.count,
            };

        default:
            return state;
    }
}