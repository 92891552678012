import serviceProvider from "../Services/serviceProvider";
import { showToast } from "./toast";
import { GET_PAST_JOB_PROVIDER, GET_SERVICE_PROVIDERS, GET_SINGLE_PROVIDER, GET_TRANSACTION_HISTORY_PROVIDER, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getServiceProvidersAction =
  (page, limit, search) => async (dispatch) => {
    return serviceProvider
      .getServiceProviders(page, limit, search)
      .then((resp) => {
        if (resp.status) {
          dispatch({ type: GET_SERVICE_PROVIDERS, payload: resp.data });
        } else {
          //   dispatch(showToast(resp?.data, "error"));
        }
      });
  };


export const getPastJobsProviderAction =
  (id, page, limit, status) => async (dispatch) => {
    return serviceProvider
      .getPastJobsProviders(id, page, limit, status)
      .then((resp) => {
        if (resp.status) {
          dispatch({ type: GET_PAST_JOB_PROVIDER, payload: resp.data });
        } else {
          //   dispatch(showToast(resp?.data, "error"));
        }
      });
  };

export const getSingleProviderDetail =
  (id) => async (dispatch) => {
    return serviceProvider
      .getSingleProvider(id)
      .then((resp) => {
        if (resp.status) {
          dispatch({ type: GET_SINGLE_PROVIDER, payload: resp.data });
        } else {
          //   dispatch(showToast(resp?.data, "error"));
        }
      });
  };

export const getTransHistoryProvider =
  (page, limit, date, status, type, id) => async (dispatch) => {
    return serviceProvider
      .getTransHistoryProvider(page, limit, date, status, type, id)
      .then((resp) => {
        if (resp.status) {
          dispatch({ type: GET_TRANSACTION_HISTORY_PROVIDER, payload: resp.data });
        } else {
          //   dispatch(showToast(resp?.data, "error"));
        }
      });
  };


export const deactivateProviderAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await serviceProvider.deActivateProvider(payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const activateProviderAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await serviceProvider.activateProvider(payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const updateProviderPasswordAction = (id, payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await serviceProvider.updatePassword(id, payload);
  if (resp.status) {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp?.data });
    dispatch(stopLoaderAction());
  } else {
    dispatch({ type: SET_FAILED_MESSAGE, payload: resp?.data });
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const updateProviderProfile = (id, payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await serviceProvider.updateProfile(id, payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, 'success'))
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, 'error'))
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};


export const sendPaymentToProvider = (id, payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await serviceProvider.sendPaymnet(id, payload);
  if (resp.status) {
    dispatch(showToast(resp?.data, "success"));
    // dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp?.data });
    dispatch(stopLoaderAction());
  } else {
    dispatch(showToast(resp?.data, "error"));
    dispatch({ type: SET_FAILED_MESSAGE, payload: resp?.data });
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};
