import { useEffect, useState } from "react";
import { backendUrl } from "../../constants";
import { uploadFileAction } from "../../Action/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { addBannerAction, editBannerAction } from "../../Action/banner";
import { RoutesPaths } from "../../Router/constant";
import { useLocation, useNavigate } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { getServicesAction } from "../../Action/Services";
import { addQuestionAction, editQuestionAction } from "../../Action/question";

export default function AddEditQuestion() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    mode: "onChange", // Enable validation on change
  });
  const answerFiled = watch("answer");
  const { categoryList } = useSelector((state) => state.category);
  const { servicesList, count } = useSelector((state) => state.services);

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [answer, setAnswer] = useState([]);
  const [error, setError] = useState(false);

  const location = useLocation();
  const { question } = location.state || {};
  const editData = question;
  useEffect(() => {
    dispatch(getServicesAction());

    if (question) {
      reset(question);
      setAnswer(question?.answers);
      let array = [question?.service];
      setSelectedCategory(array);
      setValue("service", array);
    }
  }, []);

  const navigate = useNavigate();

  const onSelect = (selectedList) => {
    const selectedIds = selectedList.map((item) => item._id); // Get selected service IDs
    setSelectedCategory(selectedIds); // Update state
    setValue("service", selectedIds); // Update form value to trigger validation
  };

  const onRemove = (selectedList) => {
    const selectedIds = selectedList.map((item) => item._id);
    setSelectedCategory(selectedIds);
    setValue("service", selectedIds);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            setImagePreviewUrl(response?.data?.fileName);
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const onSubmit = (data, event) => {
    if (answer?.length == 0) {
      setError(true);
      return;
    }
    // if (isSubmitting) return;

    // // setIsSubmitting(true);
    const { question, IsMultiple, isOther, isRequired, service, isActive } =
      data;
    const dataToSave = {
      question: question,
      isMultiple: IsMultiple,
      isActive: isActive,
      isOther: isOther,
      service: service[0],
      answers: answer,
      id: editData?._id,
    };

    dispatch(
      editData ? editQuestionAction(dataToSave) : addQuestionAction(dataToSave)
    )
      .then(() => {
        navigate(RoutesPaths.QUESTION);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Function to add input to the list
  const addItemToList = () => {
    if (answer?.length == 0) {
      setError(true);
    } else {
      setError(false);
    }

    if (answerFiled.trim()) {
      setAnswer([...answer, answerFiled]);
      setInputValue("");
      setValue("answer", "");
    }
  };

  // Function to delete item from the list
  const deleteItem = (index) => {
    const newData = answer.filter((item, i) => i !== index);
    setAnswer(newData);
  };

  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header">
                  <div className="card-title fs-3 fw-bold">
                    Add/Edit Question
                  </div>
                </div>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="card-body p-9">
                    <div className="row mb-8">
                      <div className="col-md-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Service *
                        </div>
                      </div>
                      <div className="col-md-9 fv-row">
                        <Multiselect
                          name="service"
                          className="form-control form-control-solid custom-multiselect"
                          options={servicesList} // Options to display in the dropdown
                          singleSelect={true} // Enable single item selection
                          selectedValues={servicesList.filter((item) =>
                            selectedCategory.includes(item._id)
                          )} // Preselected value to persist in dropdown
                          onSelect={onSelect} // Function will trigger on select event
                          onRemove={onRemove} // Function will trigger on remove event
                          displayValue="title" // Property name to display in the dropdown options
                          {...register("service", {
                            required: "Service is required.", // Validation message
                            validate: (value) =>
                              value.length > 0 || "Service is required.", // Custom validation
                          })}
                        />
                        {errors.service && (
                          <div className="text-danger">
                            {errors.service.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Question
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row ">
                        <input
                          type="text"
                          className="form-control form-control-solid custom-multiselect"
                          name="question"
                          placeholder="Enter question"
                          {...register("question", {
                            required: "question is required.",
                          })}
                        />
                        {errors.question && (
                          <div className="text-danger">
                            {errors.question.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Answer</div>
                      </div>
                      <div className="col-xl-7 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid custom-multiselect"
                          name="answer"
                          placeholder="Enter answer"
                          onChange={(e) => setInputValue(e.target.value)}
                          {...register("answer", {
                            // required: "answer is required.",
                          })}
                        />
                        {error && (
                          <div className="text-danger">answer is required</div>
                        )}
                      </div>
                      <div className="col-xl-2 fv-row">
                        <button
                          type="button" // Added type="button" to prevent form submission
                          onClick={addItemToList}
                          style={{ padding: "10px" }}
                        >
                          Add to List
                        </button>
                      </div>
                    </div>

                    {/* Display the list */}
                    <div className="row mb-8">
                      {answer?.map((item, index) => (
                        <>
                          <div className="col-md-3"></div>
                          <div className="col-md-7 ">
                            <li
                              key={index}
                              style={{ margin: "5px 0" }}
                              className="form-control form-control-solid custom-multiselect"
                            >
                              {item}
                            </li>
                          </div>
                          <div className="col-md-2 mt-1">
                            <button
                              onClick={() => deleteItem(index)}
                              style={{}}
                            >
                              <img src="assets/img/crossButton.svg" alt="" />
                            </button>
                          </div>
                        </>
                      ))}
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          IsMultiple
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <Controller
                            name="isMultiple"
                            control={control}
                            defaultValue={status}
                            render={({ onChange, value }) => (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="isMultiple"
                                checked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                }}
                                {...register("isMultiple")}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          IsOther
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <Controller
                            name="isOther"
                            control={control}
                            defaultValue={status}
                            render={({ onChange, value }) => (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="isOther"
                                checked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                }}
                                {...register("isOther")}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          IsActive
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <Controller
                            name="isActive"
                            control={control}
                            defaultValue={status}
                            render={({ onChange, value }) => (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="isActive"
                                checked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                }}
                                {...register("isActive")}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mb-5">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Banner Image
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px bgi-position-center"
                            style={{
                              backgroundImage: `url("${
                                imagePreviewUrl
                                  ? `${backendUrl + imagePreviewUrl}`
                                  : "assets/img/dummy-image-square.jpg"
                              }")`,
                            }}
                          />
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                          >
                            <i className="ki-outline ki-pencil fs-7" />
                            <input
                              type="file"
                              name="image"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleImageChange}
                            />
                            <input
                              type="hidden"
                              name="image"
                              {...register("image")}
                            />
                          </label>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                          {imagePreviewUrl && (
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                              onClick={removeImage}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                        </div>
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {errors.image && (
                          <div className="text-danger">
                            {errors.image.message}
                          </div>
                        )}
                      </div>
                    </div> */}

                    {/* <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Role</div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <select
                          className="form-control-solid fs-6 fw-bold customOptions"
                          name="role"
                          {...register("role", {
                            required: "Banner role is required.",
                            onChange: (e) => setRole(e.target.value),
                          })}
                        >
                          <option value="" disabled selected hidden>
                            Select an option
                          </option>
                          <option value="1">Customer</option>
                          <option value="2">Provider</option>
                        </select>
                        {errors.role && (
                          <div className="text-danger">
                            {errors.role.message}
                          </div>
                        )}
                      </div>
                    </div> */}

                    {/* <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Type</div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <select
                          className="form-control-solid fs-6 fw-bold customOptions"
                          name="type"
                          {...register("type", {
                            required: "Banner type is required.",
                            onChange: (e) => setType(e.target.value),
                          })}
                          disabled={!role}
                        >
                          <option value="" disabled selected hidden>
                            Select an option
                          </option>
                          {role == "1" && (
                            <>
                              <option value="2">Provider</option>
                              <option value="3">Service</option>
                            </>
                          )}
                          <option value="4">External link</option>
                          <option value="5">No CTA {type}</option>
                        </select>
                        {errors.type && (
                          <div className="text-danger">
                            {errors.type.message}
                          </div>
                        )}
                      </div>
                    </div> */}

                    {/* {type != "5" && (
                      <>
                        <div className="row mb-8">
                          <div className="col-xl-3">
                            <div className="fs-6 fw-semibold mt-2 mb-3">
                              {type === "4" ? "External link" : "Type Id"}
                            </div>
                          </div>
                          <div className="col-xl-9 fv-row">
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="typeId"
                              placeholder={
                                type === "4" ? "External link" : "Type Id"
                              }
                              {...register("typeId", {
                                required: "Type id is required.",
                              })}
                            />
                            {errors.typeId && (
                              <div className="text-danger">
                                {errors.typeId.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )} */}
                    {/* <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Status</div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="status"
                            name="status"
                            checked={status}
                            onChange={() => setStatus(!status)}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={() => reset()}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      // disabled={isSubmitting || !isValid}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
