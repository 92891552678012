import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredRevenue,
  getRevenueTrackingDetail,
} from "../../Action/RevenueTracking";
import { backendUrl } from "../../constants";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from "recharts";

export default function RevenueTracking() {
  const { revenueTrackingData, revenueFilterData } = useSelector(
    (state) => state.revenueTracking
  );
  const dispatch = useDispatch();
  const [type, setType] = useState("year");

  useEffect(() => {
    dispatch(getRevenueTrackingDetail());
  }, []);

  useEffect(() => {
    dispatch(getFilteredRevenue(type));
  }, [type]);

  const setFilterFunction = (val) => {
    setType(val);
  };

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };
  return (
    <>
      <div className="container-fluid">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-fluid d-flex align-items-stretch pt-7 pt-lg-10"
        >
          <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                Revenue tracking
              </h1>
            </div>
          </div>
        </div>
        <div className="row p-10">
          <div className="col-md-8">
            {/* <div className="row mb-10">
              <div className="col-md-4">
                <a
                  class="card flex-column justfiy-content-start align-items-start text-start w-100 text-gray-800 text-hover-primary p-10"
                  href=" "
                >
                  <img src="assets/img/chart-simple.svg" alt="image" />
                  <span class="fs-4 fw-bold">500M$ </span>
                  <span class="fs-6 fw-bold text-gray-500">
                    SAP UI Progress{" "}
                  </span>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  class="card flex-column justfiy-content-start align-items-start text-start w-100 text-gray-800 text-hover-primary p-10"
                  href=""
                >
                  <img src="assets/img/chart-simple.svg" alt="image" />
                  <span class="fs-4 fw-bold">400M$ </span>
                  <span class="fs-6 fw-bold text-gray-500">
                    SAP UI Progress{" "}
                  </span>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  class="card flex-column justfiy-content-start align-items-start text-start w-100 text-gray-800 text-hover-primary p-10"
                  href=""
                >
                  <img src="assets/img/chart-simple.svg" alt="image" />
                  <span class="fs-4 fw-bold">300M$ </span>
                  <span class="fs-6 fw-bold text-gray-500">
                    SAP UI Progress{" "}
                  </span>
                </a>
              </div>
            </div> */}

            <div className="card card-xl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Overview</span>
                  <span className="text-muted mt-1 fw-semibold fs-7">
                    in Milllions
                  </span>
                </h3>
                <div className="card-toolbar">
                  <ul className="nav" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4 me-1 active"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_1"
                        aria-selected="true"
                        role="tab"
                        onClick={() => {
                          setFilterFunction("year");
                        }}
                      >
                        Year
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4 me-1"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_2"
                        aria-selected="false"
                        role="tab"
                        tabIndex={-1}
                        onClick={() => {
                          setFilterFunction("month");
                        }}
                      >
                        Month
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_3"
                        aria-selected="false"
                        role="tab"
                        tabIndex={-1}
                        onClick={() => {
                          setFilterFunction("week");
                        }}
                      >
                        Week
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_3"
                        aria-selected="false"
                        role="tab"
                        tabIndex={-1}
                        onClick={() => {
                          setFilterFunction("day");
                        }}
                      >
                        Day
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Chart*/}
                <div
                  id="kt_charts_widget_1_chart"
                  style={{ height: 350, minHeight: 365 }}
                >
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={revenueFilterData}
                      margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="totalRevenue" fill="#8884d8" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                {/*end::Chart*/}
              </div>
              {/*end::Body*/}
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-flush mb-5">
              <div className="card-header pt-7 mb-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Popular services based on Revenue
                  </span>
                </h3>
              </div>
              <div className="card-body pt-0">
                <div className="m-0">
                  {revenueTrackingData?.popularServicesByRevenue?.map(
                    (item) => {
                      return (
                        <>
                          <div className="d-flex flex-stack py-3">
                            <img
                              src={backendUrl + item?.serviceImage}
                              className="me-4 w-40px h-40px"
                              style={{ borderRadius: 4 }}
                              alt=""
                              onError={handleError}
                            />
                            <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                              <div className="me-5">
                                <div
                                  href="#"
                                  className="text-gray-800 fw-bold text-hover-primary fs-6"
                                >
                                  {item?.serviceName}
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="m-0">
                                  <span className="badge badge-light-success fs-base">
                                    {/* <i className="ki-outline ki-plus fs-5 text-success ms-n1" /> */}
                                    {item?.totalRevenue}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-3" />
                        </>
                      );
                    }
                  )}
                  {/* <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/PopularAuthors.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Popular Authors
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            0.4$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/NewUsers.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          New Users
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            0.2$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/ActiveCustomers.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Active Customers
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            4.1$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/BestsellerTheme.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Bestseller Theme
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            8.3$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/FoxBrokerApp.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Fox Broker App
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            1.9$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="card card-flush mb-5">
              <div className="card-header pt-7 mb-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="fw-bold text-gray-800">
                    Top Revenue Providers
                  </span>
                </h3>
              </div>
              <div className="card-body pt-0">
                <div className="m-0">
                  {revenueTrackingData?.topRevenueProviders?.map((item) => {
                    return (
                      <div>
                        <div className="d-flex flex-stack py-3">
                          <img
                            src={backendUrl + item?.providerProfilePic}
                            className="me-4 w-40px h-40px"
                            style={{ borderRadius: 4 }}
                            alt=""
                            onError={handleError}
                          />
                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                            <div className="me-5">
                              <div
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                {item?.providerName}
                              </div>
                              <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                {item?.providerEmail}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="m-0">
                                <span className="badge badge-light-success fs-base">
                                  {/* <i className= "ki-outline ki-plus fs-5 text-success ms-n1" /> */}
                                  {item?.totalRevenue}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
