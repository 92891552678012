import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// import PrivateRoute from "./PrivateRoute";
// import PublicRoute from "./PublicRoute";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { RoutesPaths } from "./constant";
import User from "../Pages/User/User";
import UserOverview from "../Pages/User/UserOverview";
import ServiceProvider from "../Pages/ServiceProvider/ServiceProvider";
import ServiceProviderOverview from "../Pages/ServiceProvider/ServiceProviderOverview";
import ServiceProviderManage from "../Pages/ServiceProvider/ServiceProviderManage";
import Login from "../Pages/Auth/Login";
import DiscountCoupon from "../Pages/CreateCoupon/DiscountCoupon";
import CreateCoupon from "../Pages/CreateCoupon/CreateCoupon";
import NotificationCenter from "../Pages/Notification/NotificationCenter";
import Service from "../Pages/Service/Service";
import RevenueTracking from "../Pages/RevenueTracking/RevenueTracking";
import Profile from "../Pages/Profile/Profile";
import EditProfile from "../Pages/Profile/Editprofile";
import Editcategory from "../Pages/Category/Editcategory";
import AddEditServices from "../Pages/Service/AddEditServices";
import Category from "../Pages/Category/Category";
import Banners from "../Pages/Banner/Banners.jsx";
import Banner from "../Pages/Banner/Banner";
import Questions from "../Pages/Question/Questions.jsx";
import AddEditQuestion from "../Pages/Question/AddEditQuestion.jsx";
import Notification from "../Pages/Notification/Notification.jsx";
import UserJobDetail from "../Pages/User/UserJobDetail.jsx";
import Jobs from "../Pages/Jobs/jobs.jsx";
import UserEditProfile from "../Pages/User/UserEditProfile.jsx";

export default function RouteComponent() {
  return (
    <>
      <Routes>
        <Route exact path={RoutesPaths.HOME} element={<Login />} />
        <Route exact path={RoutesPaths.LOGIN} element={<Login />} />
        <Route exact path={RoutesPaths.DASHBOARD} element={<Dashboard />} />
        <Route exact path={RoutesPaths.USER_MANAGER} element={<User />} />
        <Route
          exact
          path={RoutesPaths.OVERVIEW_USER_MANAGER}
          element={<UserOverview />}
        />
        <Route
          exact
          path={RoutesPaths.SERVICE_PROVIDER}
          element={<ServiceProvider />}
        />
        <Route
          exact
          path={RoutesPaths.OVERVIEW_SERVICE_PROVIDER}
          element={<ServiceProviderOverview />}
        />
        <Route
          exact
          path={RoutesPaths.MANAGE_SERVICE_PROVIDER}
          element={<ServiceProviderManage />}
        />
        <Route exact path={RoutesPaths.SERVICES} element={<Service />} />
        <Route
          exact
          path={RoutesPaths.ADDEDITSERVICES}
          element={<AddEditServices />}
        />
        <Route
          exact
          path={RoutesPaths.REVENUE_TRACKING}
          element={<RevenueTracking />}
        />
        <Route
          exact
          path={RoutesPaths.NOTIFICATION_CENTER}
          element={<NotificationCenter />}
        />
        <Route exact path={RoutesPaths.COUPON} element={<DiscountCoupon />} />
        <Route
          exact
          path={RoutesPaths.CREATECOUPON}
          element={<CreateCoupon />}
        />
        <Route exact path={RoutesPaths.PROFILE} element={<Profile />} />
        <Route exact path={RoutesPaths.EDITPROFILE} element={<EditProfile />} />
        <Route exact path={RoutesPaths.CATEGORY} element={<Category />} />
        <Route
          exact
          path={RoutesPaths.EDITCATEGORY}
          element={<Editcategory />}
        />
        <Route exact path={RoutesPaths.BANNERS} element={<Banners />} />
        <Route exact path={RoutesPaths.BANNER} element={<Banner />} />
        <Route exact path={RoutesPaths.QUESTION} element={<Questions />} />
        <Route exact path={RoutesPaths.QUESTIONS} element={<AddEditQuestion />} />
        <Route exact path={RoutesPaths.NOTIFICATION} element={<Notification />} />
        <Route exact path={RoutesPaths.JOB_DETAIL} element={<UserJobDetail />} />
        <Route exact path={RoutesPaths.JOBS} element={<Jobs />} />
        <Route exact path={RoutesPaths.UserEditProfile} element={<UserEditProfile />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}
