import { GET_CATEGORY, GET_NOTIFICATION } from "../Action/type";

const initialState = {
    notificaionList: [],
    count: 0,
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                notificaionList: payload,
                // count: payload?.count,
            };

        default:
            return state;
    }
}
