import { NavLink, useLocation } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { backendUrl, currency } from "../../constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateProviderAction,
  deactivateProviderAction,
  getPastJobsProviderAction,
  getSingleProviderDetail,
  getTransHistoryProvider,
  updateProviderPasswordAction,
} from "../../Action/serviceProvider";
import moment from "moment";
import { getNotificationSettingList } from "../../Action/user";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useForm } from "react-hook-form";

export default function ServiceProviderOverview() {
  const location = useLocation();
  const { serviceProvider } = location.state || {};
  const dispatch = useDispatch();
  const {
    pastJobProvider,
    countPastJobProvider,
    singleProvider,
    transactionHistoryProvider,
    countTransactionHistoryProvider,
  } = useSelector((state) => state.serviceProvider);

  const { notificationSettingList } = useSelector((state) => state.user);

  const [view, setView] = useState("profile");
  const recordsPerPage = 10;

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // State to track checkbox status
  const [isOpen, setIsOpen] = useState(false); // State to manage calendar visibility
  const [confirmedDates, setConfirmedDates] = useState([]); // State to hold confirmed dates
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState([]);
  const [historyStatus, setHistoryStatus] = useState("");
  const [currentHistoryPage, setCurrentHistoryPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(countPastJobProvider / recordsPerPage);
  const totalHistoryPages = Math.ceil(
    countTransactionHistoryProvider / recordsPerPage
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    reset,
    watch,
    control,
  } = useForm({
    mode: "onChange", // Enable validation on change
  });
  useEffect(() => {
    dispatch(
      getPastJobsProviderAction(
        serviceProvider?._id,
        currentPage,
        recordsPerPage,
        status
      )
    );
  }, [currentPage, status]);

  useEffect(() => {
    dispatch(getSingleProviderDetail(serviceProvider?._id));
    dispatch(getNotificationSettingList());
  }, []);

  useEffect(() => {
    dispatch(
      getTransHistoryProvider(
        currentHistoryPage,
        recordsPerPage,
        confirmedDates,
        historyStatus,
        type,
        serviceProvider?._id
      )
    );
  }, [currentHistoryPage, confirmedDates, historyStatus, type]);

  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };
  const handleShowView = (val) => {
    setView(val);
  };

  const filteredPushNotifications = notificationSettingList?.filter(
    (item) => item.notificationType === 1 && item.userType === 2
  );

  const filteredEmailNotifications = notificationSettingList?.filter(
    (item) => item.notificationType === 2 && item.userType === 2
  );

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    // Perform actions based on the selected value
    setCurrentPage(0);
    setStatus(selectedValue);
  };

  const handleSelectChangeFilter = (event, val) => {
    const selectedValue = event.target.value;
    // Perform actions based on the selected value
    if (val === "type") {
      setType(selectedValue);
    } else {
      setHistoryStatus(selectedValue);
    }
  };

  const handleToggleCalendar = () => {
    setIsOpen(!isOpen); // Toggle calendar visibility
  };

  const handleConfirm = () => {
    setConfirmedDates(date); // Store confirmed dates
    setIsOpen(false); // Close the calendar
  };

  const handleCancel = () => {
    setDate(null); // Clear the selection
    setIsOpen(false); // Close the calendar
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleHistoryPageChange = (page) => {
    setCurrentHistoryPage(page);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // Adjust this to show more/less page numbers

    if (totalPages <= maxPageNumbers) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfPageNumbers = Math.floor(maxPageNumbers / 2);
      let startPage = Math.max(currentPage - halfPageNumbers, 0);
      let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages - 1);

      if (currentPage > totalPages - halfPageNumbers) {
        startPage = totalPages - maxPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const generatePageNumbersOfHistory = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // Adjust this to show more/less page numbers

    if (totalHistoryPages <= maxPageNumbers) {
      for (let i = 0; i < totalHistoryPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfPageNumbers = Math.floor(maxPageNumbers / 2);
      let startPage = Math.max(currentHistoryPage - halfPageNumbers, 0);
      let endPage = Math.min(
        startPage + maxPageNumbers - 1,
        totalHistoryPages - 1
      );

      if (currentHistoryPage > totalHistoryPages - halfPageNumbers) {
        startPage = totalHistoryPages - maxPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const deactivateUser = () => {
    dispatch(deactivateProviderAction(serviceProvider?._id))
      .then(() => {
        dispatch(getSingleProviderDetail(serviceProvider?._id));
        setShowPopup(false);
      })
      .finally(() => {});
  };

  const activateUserFunction = () => {
    dispatch(activateProviderAction(serviceProvider?._id))
      .then(() => {
        dispatch(getSingleProviderDetail(serviceProvider?._id));
        setShowPopup(false);
      })
      .finally(() => {});
  };

  const handleShow = () => {
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const onSubmit = (data) => {
    const formData = {
      password: data.newpassword,
    };
    dispatch(updateProviderPasswordAction(serviceProvider?._id, formData))
      .then(() => {
        reset();
      })
      .finally(() => {});
  };

  const AlertPopup = ({}) => {
    return (
      <div
        className={`modal modal-confirmation ${showPopup ? "show" : ""}`}
        style={{ display: showPopup ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <h2>Confirmation</h2>
          <p>
            Are you sure you want to{" "}
            {singleProvider?.isDeactivatedByAdmin ? "Activate" : "Deactivate"}{" "}
            this User ?
          </p>
          <div className="modal-footer">
            <button className="secondary" onClick={handleClose}>
              No
            </button>
            <button
              className="primary"
              onClick={() => {
                singleProvider?.isDeactivatedByAdmin
                  ? activateUserFunction()
                  : deactivateUser();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <AlertPopup />
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="row">
                <div className="d-flex justify-content-start segment-section">
                  <button
                    className={`btn ${
                      view === "profile" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => {
                      handleShowView("profile");
                    }}
                  >
                    Profile
                  </button>
                  <button
                    className={`btn ${
                      view === "jobs" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => {
                      handleShowView("jobs");
                    }}
                  >
                    Past jobs
                  </button>
                  <button
                    className={`btn ${
                      view === "notification" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => {
                      handleShowView("notification");
                    }}
                  >
                    Notification Settings
                  </button>
                  <button
                    className={`btn  ${
                      view === "history" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => {
                      handleShowView("history");
                    }}
                  >
                    Transaction History
                  </button>

                  <button
                    className={`btn  ${
                      view === "password" ? "btn-primary" : "btn-light"
                    }`}
                    onClick={() => {
                      handleShowView("password");
                    }}
                  >
                    Update Password
                  </button>
                </div>
              </div>

              {/*begin::Navbar*/}

              {view === "profile" && (
                <>
                  <div className="card mb-5 mb-xxl-8 mt-10">
                    <div className="card-body pt-9 pb-6">
                      {/*begin::Details*/}
                      <div className="d-flex flex-wrap flex-sm-nowrap">
                        {/*begin: Pic*/}
                        <div className="me-7 mb-4">
                          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img
                              className="image-holder"
                              src={backendUrl + singleProvider.profilePic}
                              alt=""
                              onError={handleError} // handle the error event
                            />
                            <div
                              className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px ${
                                !singleProvider.isDeactivatedByAdmin
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            />
                          </div>
                        </div>
                        {/*end::Pic*/}
                        {/*begin::Info*/}
                        <div className="flex-grow-1">
                          {/*begin::Title*/}
                          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            {/*begin::User*/}
                            <div className="d-flex flex-column">
                              {/*begin::Name*/}
                              <div className="d-flex align-items-center mb-2">
                                <span
                                  href="#"
                                  className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                >
                                  {singleProvider?.fullName}
                                </span>
                                {/* <a href="#"> */}
                                <i className="ki-outline ki-verify fs-1 text-primary" />
                                {/* </a> */}
                              </div>
                              {/*end::Name*/}
                              {/*begin::Info*/}
                              <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                {/* <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                  <i className="ki-outline ki-profile-circle fs-4 me-1" />
                                  Developer
                                </span> */}
                                {singleProvider?.address && (
                                  <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                    <i className="ki-outline ki-geolocation fs-4 me-1" />
                                    {singleProvider?.address}
                                  </span>
                                )}
                                <span className="d-flex align-items-center text-gray-500 text-hover-primary mb-2">
                                  <i className="ki-outline ki-sms fs-4 me-1" />
                                  {singleProvider?.email}
                                </span>
                              </div>
                              {/*end::Info*/}
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Title*/}
                          {/*begin::Stats*/}
                          <div className="d-flex flex-wrap flex-stack">
                            {/*begin::Wrapper*/}
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                              {/*begin::Stats*/}
                              <div className="d-flex flex-wrap">
                                {/*begin::Stat*/}
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  {/*begin::Number*/}
                                  <div className="d-flex align-items-center">
                                    <i className="ki-outline ki-arrow-up fs-3 text-success me-2" />
                                    <div
                                      className="fs-2 fw-bold"
                                      data-kt-countup="true"
                                      data-kt-countup-value={4500}
                                      data-kt-countup-prefix="$"
                                    >
                                      0
                                    </div>
                                  </div>
                                  {/*end::Number*/}
                                  {/*begin::Label*/}
                                  <div className="fw-semibold fs-6 text-gray-500">
                                    Earnings
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Stat*/}
                                {/*begin::Stat*/}
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  {/*begin::Number*/}
                                  <div className="d-flex align-items-center">
                                    <i className="ki-outline ki-arrow-down fs-3 text-danger me-2" />
                                    <div
                                      className="fs-2 fw-bold"
                                      data-kt-countup="true"
                                      data-kt-countup-value={80}
                                    >
                                      0
                                    </div>
                                  </div>
                                  {/*end::Number*/}
                                  {/*begin::Label*/}
                                  <div className="fw-semibold fs-6 text-gray-500">
                                    Projects
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Stat*/}
                                {/*begin::Stat*/}
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  {/*begin::Number*/}
                                  <div className="d-flex align-items-center">
                                    <i className="ki-outline ki-arrow-up fs-3 text-success me-2" />
                                    <div
                                      className="fs-2 fw-bold"
                                      data-kt-countup="true"
                                      data-kt-countup-value={60}
                                      data-kt-countup-prefix="%"
                                    >
                                      0
                                    </div>
                                  </div>
                                  {/*end::Number*/}
                                  {/*begin::Label*/}
                                  <div className="fw-semibold fs-6 text-gray-500">
                                    Success Rate
                                  </div>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Stat*/}
                              </div>
                              {/*end::Stats*/}
                            </div>
                            {/*end::Wrapper*/}
                          </div>
                          {/*end::Stats*/}
                        </div>
                        {/*end::Info*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>

                  {/*begin::details View*/}
                  <div
                    className="card mb-5 mb-xl-10"
                    id="kt_profile_details_view"
                  >
                    {/*begin::Card header*/}
                    <div className="card-header cursor-pointer">
                      {/*begin::Card title*/}
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Profile Details</h3>
                      </div>
                      {/*end::Card title*/}
                      {/*begin::Action*/}
                      <NavLink
                        state={{ editData: singleProvider }}
                        to={RoutesPaths.MANAGE_SERVICE_PROVIDER}
                        className="btn btn-sm btn-primary align-self-center"
                      >
                        Edit Profile
                      </NavLink>
                      {/*end::Action*/}
                    </div>
                    {/*begin::Card header*/}
                    {/*begin::Card body*/}
                    <div className="card-body p-9">
                      {/*begin::Row*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Full Name
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          <span className="fw-bold fs-6 text-gray-800">
                            {singleProvider?.fullName}
                          </span>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Input group*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Area Radius
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8 fv-row">
                          <span className="fw-semibold text-gray-800 fs-6">
                            {singleProvider?.areaRadius}
                          </span>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Contact Phone
                          <span
                            className="ms-1"
                            data-bs-toggle="tooltip"
                            title="Phone number must be active"
                          >
                            <i className="ki-outline ki-information fs-7" />
                          </span>
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8 d-flex align-items-center">
                          <span className="fw-bold fs-6 text-gray-800 me-2">
                            +27 {singleProvider.mobileNumber}
                          </span>
                          <span
                            className={`badge ${
                              singleProvider.isOtpVerified
                                ? "badge-success"
                                : "badge-danger"
                            }`}
                          >
                            {singleProvider.isOtpVerified
                              ? "Verified"
                              : "Not verified"}
                          </span>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Address
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800 text-hover-primary">
                            {singleProvider?.address ?? "--"}
                          </span>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Business Address
                          <span
                            className="ms-1"
                            data-bs-toggle="tooltip"
                            title="Country of origination"
                          >
                            <i className="ki-outline ki-information fs-7" />
                          </span>
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          <span className="fw-bold fs-6 text-gray-800">
                            {singleProvider?.businessAddress ?? "--"}
                          </span>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="row mb-7">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Team Size
                        </label>
                        {/*end::Label*/}
                        {/*begin::Col*/}
                        <div className="col-lg-8">
                          <span className="fw-bold fs-6 text-gray-800">
                            {singleProvider?.teamSize ?? "--"}
                          </span>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className="row mb-10">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Experience
                        </label>
                        {/*begin::Label*/}
                        {/*begin::Label*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {singleProvider?.experience ?? "0"}
                          </span>
                        </div>
                        {/*begin::Label*/}
                      </div>
                      <div className="row mb-10">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Website
                        </label>
                        {/*begin::Label*/}
                        {/*begin::Label*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {singleProvider?.website ?? "--"}
                          </span>
                        </div>
                        {/*begin::Label*/}
                      </div>
                      <div className="row mb-10">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          IsBusiness Address Private
                        </label>
                        {/*begin::Label*/}
                        {/*begin::Label*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {JSON.stringify(
                              singleProvider?.isBusinessAddressPrivate
                            ) ?? "--"}
                          </span>
                        </div>
                        {/*begin::Label*/}
                      </div>
                      <div className="row mb-10">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Company Facebook Page
                        </label>
                        {/*begin::Label*/}
                        {/*begin::Label*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {singleProvider?.companyFacebookPage ?? "--"}
                          </span>
                        </div>
                        {/*begin::Label*/}
                      </div>
                      <div className="row mb-10">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          Facebook Url
                        </label>
                        {/*begin::Label*/}
                        {/*begin::Label*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {singleProvider?.facebookUrl ?? "--"}
                          </span>
                        </div>
                        {/*begin::Label*/}
                      </div>
                      <div className="row mb-10">
                        {/*begin::Label*/}
                        <label className="col-lg-4 fw-semibold text-muted">
                          twitter Url
                        </label>
                        {/*begin::Label*/}
                        {/*begin::Label*/}
                        <div className="col-lg-8">
                          <span className="fw-semibold fs-6 text-gray-800">
                            {singleProvider?.twitterUrl ?? "--"}
                          </span>
                        </div>
                        {/*begin::Label*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                  {/*end::details View*/}

                  {/*begin::Deactivate Account*/}
                  <div className="card">
                    {/*begin::Card header*/}
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_deactivate"
                      aria-expanded="true"
                      aria-controls="kt_account_deactivate"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">
                          Activate/Deactivate Account
                        </h3>
                      </div>
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Content*/}
                    <div
                      id="kt_account_settings_deactivate"
                      className="collapse show"
                    >
                      {/*begin::Form*/}
                      {/*begin::Card body*/}
                      <div className="card-body border-top p-9">
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                          {/*begin::Icon*/}
                          <i className="ki-outline ki-information fs-2tx text-warning me-4" />
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-stack flex-grow-1">
                            {/*begin::Content*/}
                            <div className="fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                You Are{" "}
                                {singleProvider?.isDeactivatedByAdmin
                                  ? "Activation"
                                  : "Deactivation"}{" "}
                                Your Account
                              </h4>
                              <div className="fs-6 text-gray-700">
                                {singleProvider?.isDeactivatedByAdmin
                                  ? "The admin can restore full access to the account, allowing the user to use all features and services"
                                  : "The admin can restrict access, making the account inactive and hiding it from other users."}
                                <br />
                                {/* <a className="fw-bold">Learn more</a> */}
                              </div>
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                        {/*begin::Form input row*/}
                        <div className="form-check form-check-solid fv-row">
                          <input
                            name="deactivate"
                            className="form-check-input"
                            type="checkbox"
                            // defaultValue={setIsChecked}
                            id="deactivate"
                            required
                            onChange={() => {
                              setIsChecked(!isChecked);
                            }}
                          />
                          <label
                            className="form-check-label fw-semibold ps-2 fs-6"
                            htmlFor="deactivate"
                          >
                            I confirm my account{" "}
                            {singleProvider?.isDeactivatedByAdmin
                              ? "Activation"
                              : "Deactivation"}
                          </label>
                        </div>
                        {/*end::Form input row*/}
                      </div>
                      {/*end::Card body*/}
                      {/*begin::Card footer*/}
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        {!singleProvider?.isDeactivatedByAdmin ? (
                          <button
                            id="kt_account_deactivate_account_submit"
                            type="submit"
                            className="btn btn-danger fw-semibold"
                            disabled={!isChecked}
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            Deactivate Account
                          </button>
                        ) : (
                          <button
                            id="kt_account_deactivate_account_submit"
                            type="submit"
                            className="btn btn-danger fw-semibold"
                            disabled={!isChecked}
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            Activate Account
                          </button>
                        )}
                      </div>
                      {/*end::Card footer*/}
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Deactivate Account*/}
                </>
              )}
              {/*end::Navbar*/}

              {view == "jobs" && (
                <>
                  <div className="row gy-5 g-xl-12 pb-10 mt-10">
                    {/*begin::Col*/}
                    <div className="col-xl-12">
                      {/*begin::Table Widget 5*/}
                      <div className="card card-flush h-xl-100">
                        {/*begin::Card header*/}
                        <div className="card-header pt-7">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-900">
                              Past Jobs
                            </span>
                            <span className="text-gray-500 mt-1 fw-semibold fs-6">
                              Total no. of {countPastJobProvider} Jobs taken
                              from this Platform
                            </span>
                          </h3>
                          {/*end::Title*/}
                          {/*begin::Actions*/}
                          <div className="card-toolbar">
                            <div className="d-flex flex-stack flex-wrap gap-4">
                              {/*begin::Destination*/}
                              {/*end::Destination*/}
                              {/*begin::Status*/}
                              <div className="d-flex align-items-center fw-bold">
                                {/*begin::Label*/}
                                <div className="text-muted fs-7 me-2">
                                  Status
                                </div>
                                {/*end::Label*/}
                                {/*begin::Select*/}
                                <select
                                  className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-dropdown-css-class="w-150px"
                                  data-placeholder="Select an option"
                                  data-kt-table-widget-5="filter_status"
                                  onChange={(event) =>
                                    handleSelectChange(event)
                                  }
                                >
                                  <option value="" selected="selected">
                                    Show All
                                  </option>
                                  <option value="1">Pending</option>
                                  <option value="2">In Progress</option>
                                  <option value="3">Completed</option>
                                  <option value="4">Close request</option>
                                </select>
                                {/*end::Select*/}
                              </div>
                            </div>
                          </div>
                          {/*end::Actions*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body">
                          {/*begin::Table*/}
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-3"
                            id="kt_table_widget_5_table"
                          >
                            {/*begin::Table head*/}
                            <thead>
                              {/*begin::Table row*/}
                              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                <th className="min-w-150px">Services</th>
                                <th className="text-center pe-3 min-w-100px">
                                  Provider
                                </th>
                                <th className="text-center pe-3 min-w-100px">
                                  User
                                </th>
                                <th className="text-end pe-3 min-w-150px">
                                  Created Date
                                </th>
                                <th className="text-center pe-3 min-w-100px">
                                  Amount
                                </th>
                                <th className="text-center pe-3 min-w-100px">
                                  Status
                                </th>
                                <th className="text-end pe-3 min-w-100px">
                                  Action
                                </th>
                              </tr>
                              {/*end::Table row*/}
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody className="fw-bold text-gray-600">
                              {pastJobProvider?.map((item) => {
                                // console.log("item", item?.status);

                                return (
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>{item?.service?.title}</td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-center">
                                      {item?.provider?.fullName ?? "-"}
                                    </td>
                                    <td className="text-center">
                                      {item?.user?.fullName ?? "-"}
                                    </td>{" "}
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">
                                      {moment(item?.createdAt).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-center">
                                      {item?.price
                                        ? currency + " " + item?.price
                                        : "-"}
                                    </td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-center">
                                      {item?.status == 1 ? (
                                        <span className="badge py-3 px-4 fs-7 badge-secondary badge-fixed-width">
                                          Pending
                                        </span>
                                      ) : item?.status == 2 ? (
                                        <span className="badge py-3 px-4 fs-7 badge-light-info badge-fixed-width">
                                          In Progress
                                        </span>
                                      ) : item?.status == 3 ? (
                                        <span className="badge py-3 px-4 fs-7 badge-light-success badge-fixed-width">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="badge py-3 px-4 fs-7 badge-light-danger badge-fixed-width">
                                          Closed
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-end">
                                      <NavLink
                                        state={{ item, type: "provider" }}
                                        to={RoutesPaths.JOB_DETAIL}
                                        className="btn btn-sm btn-primary"
                                      >
                                        View
                                      </NavLink>
                                    </td>
                                    {/*end::Status*/}
                                  </tr>
                                );
                              })}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                          {!pastJobProvider?.length ? (
                            <div className="d-flex justify-content-center align-items-center">
                              No Past Jobs
                            </div>
                          ) : null}
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Table Widget 5*/}
                    </div>
                    {/*end::Col*/}
                  </div>

                  {pastJobProvider?.length > 0 && (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => handlePageChange(0)}
                        disabled={currentPage === 0}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-left"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0zM7.354 1.646a.5.5 0 0 1 0 .708L2.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </button>
                      <button
                        className="btn btn-primary mx-2"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 0}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-left"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </button>

                      {generatePageNumbers().map((page, index) => (
                        <button
                          key={index}
                          className={`btn btn-light btn-active-light-primary mx-1 ${
                            page === currentPage ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(page)}
                        >
                          {page + 1}
                        </button>
                      ))}

                      <button
                        className="btn btn-primary mx-2"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages - 1}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-right"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => handlePageChange(totalPages - 1)}
                        disabled={currentPage === totalPages - 1}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-right"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708zm4 0a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L13.293 7 8.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </>
              )}

              {view == "notification" && (
                <div className="card mt-10">
                  {/*begin::Card header*/}
                  <div
                    className="card-header border-0 cursor-pointer row"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_deactivate"
                    aria-expanded="true"
                    aria-controls="kt_account_deactivate"
                  >
                    <div className="row">
                      <div className="card-title m-0 col-6">
                        <h3 className="fw-bold m-0">Push Notification</h3>
                      </div>

                      <div className="card-title m-0 col-6">
                        <h3 className="fw-bold m-0">Email Notification</h3>
                      </div>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Content*/}
                  <div
                    id="kt_account_settings_deactivate"
                    className="collapse show"
                  >
                    <div className="card-body border-top p-9 ">
                      <div className="row">
                        <div className="px-9 col-6">
                          {filteredPushNotifications?.map((item) => {
                            const isChecked =
                              !singleProvider?.notificationSetting?.includes(
                                item.id
                              );
                            return (
                              <div className="row mb-8">
                                <div className="col-6">
                                  <div className="fs-6 fw-semibold mt-2 mb-3">
                                    {item?.description}
                                  </div>
                                </div>
                                <div className="col-6 fv-row">
                                  <div className="form-check form-switch form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="isMultiple"
                                      checked={isChecked}
                                      onChange={(e) => {
                                        // onChange(e.target.checked);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div className="px-9 col-6">
                          {filteredEmailNotifications?.map((item) => {
                            const isChecked =
                              !singleProvider?.emailSetting?.includes(item.id);
                            return (
                              <div className="row mb-8">
                                <div className="col-6">
                                  <div className="fs-6 fw-semibold mt-2 mb-3">
                                    {item?.description}
                                  </div>
                                </div>
                                <div className="col-6 fv-row">
                                  <div className="form-check form-switch form-check-custom form-check-solid">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="isMultiple"
                                      checked={isChecked}
                                      onChange={(e) => {
                                        // onChange(e.target.checked);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
              )}

              {/* transaction history  */}
              {view == "history" && (
                <>
                  <div className="row gy-5 g-xl-12 pb-10 mt-10">
                    {/*begin::Col*/}
                    <div className="col-xl-12">
                      {/*begin::Table Widget 5*/}
                      <div className="card card-flush h-xl-100 calanderRelative">
                        {/*begin::Card header*/}
                        <div className="card-header pt-7">
                          {/*begin::Title*/}
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-900">
                              Transaction History
                            </span>
                            <span className="text-gray-500 mt-1 fw-semibold fs-6">
                              over {countTransactionHistoryProvider} Transaction
                              history
                            </span>
                          </h3>
                          {/*end::Title*/}
                          {/*begin::Actions*/}
                          <div className="card-toolbar">
                            <div className="d-flex flex-stack flex-wrap gap-4">
                              {/*begin::Status*/}
                              <div className="d-flex align-items-center fw-bold">
                                {/*begin::Label*/}
                                <div className="text-muted fs-7 me-2">Type</div>
                                {/*end::Label*/}
                                {/*begin::Select*/}
                                <select
                                  className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-dropdown-css-class="w-150px"
                                  data-placeholder="Select an option"
                                  data-kt-table-widget-5="filter_status"
                                  onChange={(event) =>
                                    handleSelectChangeFilter(event, "type")
                                  }
                                >
                                  <option value="" selected="selected"></option>
                                  <option value="selftransfer">
                                    Self Transfer
                                  </option>
                                  <option value="received">Received</option>
                                  <option value="commission">Commission</option>
                                </select>
                                {/*end::Select*/}
                              </div>
                              {/*end::Status*/}

                              {/*begin::Status*/}
                              <div className="d-flex align-items-center fw-bold">
                                {/*begin::Label*/}
                                <div className="text-muted fs-7 me-2">
                                  Status
                                </div>
                                {/*end::Label*/}
                                {/*begin::Select*/}
                                <select
                                  className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-dropdown-css-class="w-150px"
                                  data-placeholder="Select an option"
                                  data-kt-table-widget-5="filter_status"
                                  onChange={(event) =>
                                    handleSelectChangeFilter(event, "status")
                                  }
                                >
                                  <option value="" selected="selected"></option>
                                  <option value="1">Pending</option>
                                  <option value="2">Successful</option>
                                  <option value="3">Failed</option>
                                </select>
                                {/*end::Select*/}
                              </div>
                              {/*end::Status*/}

                              {/*begin::Status*/}
                              <div className="d-flex align-items-center fw-bold cursorPointer">
                                {/*begin::Label*/}
                                <span className="text-muted">By Date : </span>
                                <div
                                  className="text-muted fs-7 me-2 p-2 border rounded bg-light"
                                  onClick={() => {
                                    handleToggleCalendar();
                                  }}
                                >
                                  <span className="text-black">
                                    From Date :
                                  </span>{" "}
                                  {date && date?.length === 2
                                    ? date[0].toDateString()
                                    : ""}{" "}
                                  -{" "}
                                  <span className="text-black">To Date: </span>
                                  {date && date?.length === 2
                                    ? date[1].toDateString()
                                    : ""}
                                </div>
                                {isOpen && (
                                  <div className="calanderSection">
                                    <Calendar
                                      onChange={setDate}
                                      value={date}
                                      selectRange={true} // Enable range selection
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                      <button
                                        onClick={handleConfirm}
                                        disabled={!date}
                                      >
                                        Confirm
                                      </button>
                                      <button onClick={handleCancel}>
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {/*end::Label*/}
                              </div>
                              {/*end::Status*/}
                              {/*begin::Search*/}

                              {/*end::Search*/}
                            </div>
                          </div>
                          {/*end::Actions*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body">
                          {/*begin::Table*/}
                          <table
                            className="table align-middle table-row-dashed fs-6 gy-3"
                            id="kt_table_widget_5_table"
                          >
                            {/*begin::Table head*/}
                            <thead>
                              {/*begin::Table row*/}
                              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                <th className="min-w-150px">description</th>
                                <th className="text-end pe-3 min-w-100px">
                                  debit/Credit
                                </th>
                                <th className="text-end pe-3 min-w-150px">
                                  created date
                                </th>
                                <th className="text-end pe-3 min-w-100px">
                                  Amount
                                </th>
                                <th className="text-end pe-3 min-w-100px">
                                  Status
                                </th>
                                <th className="text-end pe-3 min-w-100px">
                                  Action
                                </th>
                              </tr>
                              {/*end::Table row*/}
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody className="fw-bold text-gray-600">
                              {transactionHistoryProvider?.map((item) => {
                                return (
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>{item?.description}</td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">
                                      {item?.debitCredit ?? "-"}
                                    </td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">
                                      {moment(item?.createdAt).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">
                                      {currency} {item?.amount}
                                    </td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      {item?.status == 1 ? (
                                        <span className="badge py-3 px-4 fs-7 badge-light-warning">
                                          Pending
                                        </span>
                                      ) : item?.status == 2 ? (
                                        <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                                          Failed
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-end">
                                      <NavLink
                                        // to={RoutesPaths.JOB_DETAIL}
                                        className="btn btn-sm btn-primary"
                                      >
                                        View
                                      </NavLink>
                                    </td>
                                    {/*end::Status*/}
                                  </tr>
                                );
                              })}
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                          {!transactionHistoryProvider?.length ? (
                            <div className="d-flex justify-content-center align-items-center">
                              No Transaction History
                            </div>
                          ) : null}
                        </div>
                        {/*end::Card body*/}
                      </div>
                      {/*end::Table Widget 5*/}
                    </div>
                    {/*end::Col*/}
                  </div>

                  {transactionHistoryProvider?.length > 0 && (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleHistoryPageChange(0)}
                        disabled={currentPage === 0}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-left"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0zM7.354 1.646a.5.5 0 0 1 0 .708L2.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </button>
                      <button
                        className="btn btn-primary mx-2"
                        onClick={() =>
                          handleHistoryPageChange(currentHistoryPage - 1)
                        }
                        disabled={currentHistoryPage === 0}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-left"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </button>

                      {generatePageNumbersOfHistory().map((page, index) => (
                        <button
                          key={index}
                          className={`btn btn-light btn-active-light-primary mx-1 ${
                            page === currentHistoryPage ? "active" : ""
                          }`}
                          onClick={() => handleHistoryPageChange(page)}
                        >
                          {page + 1}
                        </button>
                      ))}

                      <button
                        className="btn btn-primary mx-2"
                        onClick={() =>
                          handleHistoryPageChange(currentHistoryPage + 1)
                        }
                        disabled={currentHistoryPage === totalHistoryPages - 1}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-right"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleHistoryPageChange(totalHistoryPages - 1)
                        }
                        disabled={currentHistoryPage === totalHistoryPages - 1}
                      >
                        <svg
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-double-right"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708zm4 0a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L13.293 7 8.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </>
              )}

              {/* upadte Password */}
              {view == "password" && (
                <div className="card mt-10">
                  <div className="card-header">
                    <div className="card-title fs-3 fw-bold">
                      Update Password
                    </div>
                  </div>
                  <form
                    id="kt_project_settings_form"
                    className="form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="card-body p-9">
                      <div className="row mb-8">
                        <div className="col-xl-3">
                          <div className="fs-6 fw-semibold mt-2 mb-3">
                            New Password
                          </div>
                        </div>
                        <div className="col-xl-9 fv-row faEyeIconRelative">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            className="form-control form-control-solid"
                            name="newpassword"
                            placeholder="New password"
                            {...register("newpassword", {
                              required: "New password is required.",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long.",
                              },
                              // validate: {
                              //   hasUppercase: (value) =>
                              //     /[A-Z]/.test(value) ||
                              //     "Password must contain an uppercase letter.",
                              //   hasNumber: (value) =>
                              //     /\d/.test(value) ||
                              //     "Password must contain a number.",
                              // },
                            })}
                          />
                          <span
                            className="faEyeIcon"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <i class="fa-regular fa-eye"></i>
                            ) : (
                              <i class="fa-regular fa-eye-slash"></i>
                            )}
                          </span>
                          {errors.newpassword && (
                            <div className="text-danger">
                              {errors.newpassword.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-8">
                        <div className="col-xl-3">
                          <div className="fs-6 fw-semibold mt-2 mb-3">
                            Confirm New Password
                          </div>
                        </div>
                        <div className="col-xl-9 fv-row faEyeIconRelative">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control form-control-solid"
                            name="confirmnewpassword"
                            placeholder="Confirm new password"
                            {...register("confirmpassword", {
                              required: "Confirm new password is required.",
                              validate: (value) =>
                                value === getValues("newpassword") ||
                                "Passwords do not match.",
                            })}
                          />
                          <span
                            className="faEyeIcon"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? (
                              <i class="fa-regular fa-eye"></i>
                            ) : (
                              <i class="fa-regular fa-eye-slash"></i>
                            )}
                          </span>
                          {errors.confirmpassword && (
                            <div className="text-danger">
                              {errors.confirmpassword.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      {/* <button
                        type="reset"
                        className="btn btn-light btn-active-light-primary me-2"
                        onClick={() => reset()}
                      >
                        Discard
                      </button> */}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid}
                      >
                        Update password
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>

            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
      {/*end:::Main*/}
    </>
  );
}
