import axionIns from "../axios";

const getDashboard = async () => {
    return axionIns
        .get(`admin/dashboard`)
        .then((response) => {
            return response.data;
        });
};
const getDashboardRevenue = async (fromDate, toDate) => {
    return axionIns
        .get(`admin/dashboardgraph?&fromDate=${fromDate}&toDate=${toDate}`)
        .then((response) => {
            return response.data;
        });
};

export default { getDashboard, getDashboardRevenue }