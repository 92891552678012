import moment from "moment";
import axionIns from "../axios";

const getServiceProviders = async (page, limit, search) => {
  return axionIns
    .get(`admin/providers?page=${page ?? ''}&limit=${limit ?? ''}&search=${search ?? ''}`)
    .then((response) => {
      return response.data;
    });
};

const getPastJobsProviders = async (id, page, limit, status) => {
  return axionIns
    .get(`admin/providers/jobs/${id}?page=${page ?? ''}&limit=${limit ?? ''}&status=${status}`)
    .then((response) => {
      return response.data;
    });
};

const getSingleProvider = async (id) => {
  return axionIns
    .get(`admin/providers/${id}`)
    .then((response) => {
      return response.data;
    });
};

const getTransHistoryProvider = async (page, limit, date, status, type, id) => {
  return axionIns
    .get(`admin/providers/transactions/${id}?fromDate=${date?.length ? moment(date[0]).format('YYYY-MM-DD') : ''}&toDate=${date?.length ? moment(date[1]).format('YYYY-MM-DD') : ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${type ?? ''}&status=${status ?? ''}`)
    .then((response) => {
      return response.data;
    });
};

const deActivateProvider = async (payload) => {
  return axionIns
    .put("admin/providers/deactivate/" + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const activateProvider = async (payload) => {
  return axionIns
    .put("admin/providers/activate/" + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const updatePassword = async (id, payload) => {
  return axionIns
    .put("admin/providers/updatepassword/" + id, payload)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response?.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const updateProfile = async (id, payload) => {
  return axionIns
    .put("admin/providers/" + id, payload)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response?.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const sendPaymnet = async (id, payload) => {
  return axionIns
    .post("admin/paytoprovider/" + id, payload)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response?.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

export default {
  getServiceProviders,
  getPastJobsProviders,
  getSingleProvider,
  getTransHistoryProvider,

  deActivateProvider,
  activateProvider,
  updatePassword,
  updateProfile,
  sendPaymnet
};
