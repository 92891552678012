import axionIns from "../axios";

const addNotification = async (payload) => {
    return axionIns
        .post("admin/notificationsetting", JSON.stringify(payload))
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("err", JSON.stringify(error.response.data));
            return error?.response?.data ? error?.response?.data : { status: false };
        });
};

const editNotification = async (payload) => {
    return axionIns
        .put("admin/notificationsetting/" + payload._id, JSON.stringify(payload))
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("err", JSON.stringify(error.response.data));
            return error?.response?.data ? error?.response?.data : { status: false };
        });
};

const deleteNotification = async (id) => {
    return axionIns
        .delete("admin/notificationsetting/" + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("err", JSON.stringify(error.response.data));
            return error?.response?.data ? error?.response?.data : { status: false };
        });
};

const getNotification = async (page, limit, search) => {
    return axionIns
        .get(
            `admin/notificationsetting?page=${page ?? ''}&limit=${limit ? limit : ''}&search=${search ?? ''}`
        )
        .then((response) => {
            return response.data;
        });
};

export default {
    addNotification,
    editNotification,
    deleteNotification,
    getNotification
};
