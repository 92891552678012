import revenueTracking from "../Reducer/revenueTracking";
import revenueTrackingService from "../Services/revenueTrackingService";
import { showToast } from "./toast";
import { GET_FILTER_REVENUE_TRACKING, GET_REVENUE_TRACKING } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getRevenueTrackingDetail = () => async (dispatch) => {
    return revenueTrackingService.getRevenueDetail().then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_REVENUE_TRACKING, payload: resp.data });
        } else {
            dispatch(showToast(resp?.data, "error"));
        }
    });
};

export const getFilteredRevenue = (type) => async (dispatch) => {
    dispatch(startLoaderAction())
    return revenueTrackingService.getFilteredRevenueDetail(type).then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_FILTER_REVENUE_TRACKING, payload: resp.data });
        } else {
            dispatch(showToast(resp?.data, "error"));
        }
        dispatch(stopLoaderAction())
    });
};