import { useLocation } from "react-router";
import { backendUrl, currency } from "../../constants";
import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useDebounce from "../../Services/debounse";
import { getAllJobs } from "../../Action/jobs";
import moment from "moment";
import Calendar from "react-calendar";

export default function Jobs() {
  const location = useLocation();
  const { user } = location.state || {};

  const { allJobs, count } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState(1);
  const [date, setDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [confirmedDates, setConfirmedDates] = useState([]); // State to hold confirmed dates
  const debouncedSearchQuery = useDebounce(searchQuery, 500); // 500ms debounce delay
  const recordsPerPage = 10;
  const totalPages = Math.ceil(count / recordsPerPage);

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    dispatch(getAllJobs(currentPage, recordsPerPage, status, date));
  }, [currentPage, recordsPerPage, status, date]);

  console.log({ currentPage, recordsPerPage, status, date });

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // Adjust this to show more/less page numbers

    if (totalPages <= maxPageNumbers) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfPageNumbers = Math.floor(maxPageNumbers / 2);
      let startPage = Math.max(currentPage - halfPageNumbers, 0);
      let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages - 1);

      if (currentPage > totalPages - halfPageNumbers) {
        startPage = totalPages - maxPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handleSelectChange = (event) => {
    setStatus(event.target.value);
  };
  const handleToggleCalendar = () => {
    setIsOpen(!isOpen); // Toggle calendar visibility
  };

  const handleConfirm = () => {
    setConfirmedDates(date); // Store confirmed dates
    setIsOpen(false); // Close the calendar
  };

  const handleCancel = () => {
    setDate(""); // Clear the selection
    setIsOpen(false); // Close the calendar
  };

  return (
    <>
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar pt-7 pt-lg-10">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-fluid d-flex align-items-stretch"
            >
              <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                    Jobs
                  </h1>
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                    <li className="breadcrumb-item text-muted">
                      Over {count} jobs
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*begin::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-fluid d-flex align-items-stretch"
            ></div>
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <>
                <div className="row gy-5 g-xl-12 pb-10">
                  {/*begin::Col*/}
                  <div className="col-xl-12">
                    {/*begin::Table Widget 5*/}
                    <div className="card card-flush h-xl-100">
                      {/*begin::Card header*/}
                      <div className="card-header pt-7">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-900">
                            All Jobs
                          </span>
                          <span className="text-gray-500 mt-1 fw-semibold fs-6">
                            Total no.{count} of Jobs taken from this Platform
                          </span>
                        </h3>
                        {/*end::Title*/}
                        {/*begin::Actions*/}
                        <div className="card-toolbar">
                          {/*begin::Filters*/}
                          <div className="d-flex flex-stack flex-wrap gap-4">
                            {/*begin::Destination*/}
                            {/* <div className="d-flex align-items-center fw-bold">
                              <div className="text-muted fs-7 me-2">
                                Cateogry
                              </div>
                              <select
                                className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                data-control="select2"
                                data-hide-search="true"
                                data-dropdown-css-class="w-150px"
                                data-placeholder="Select an option"
                              >
                                <option />
                                <option value="Show All" selected="selected">
                                  Show All
                                </option>
                                <option value="a">Category A</option>
                                <option value="b">Category B</option>
                              </select>
                            </div> */}
                            {/*end::Destination*/}
                            {/*begin::Status*/}
                            <div className="d-flex align-items-center fw-bold">
                              {/*begin::Label*/}
                              <div className="text-muted fs-7 me-2">Status</div>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                data-control="select2"
                                data-hide-search="true"
                                data-dropdown-css-class="w-150px"
                                data-placeholder="Select an option"
                                data-kt-table-widget-5="filter_status"
                                value={status} // bind the value to state
                                onChange={handleSelectChange} // handle change
                              >
                                <option value="" selected="selected">
                                  Show All
                                </option>
                                <option value="3">Completed</option>
                                <option value="2">In Progress</option>
                                <option value="1">Pending</option>
                              </select>
                              {/*end::Select*/}
                            </div>

                            <div className="d-flex align-items-center fw-bold cursorPointer">
                              {/*begin::Label*/}
                              <span className="text-muted">By Date : </span>
                              <div
                                className="text-muted fs-7 me-2 p-2 border rounded bg-light"
                                onClick={() => {
                                  handleToggleCalendar();
                                }}
                              >
                                <span className="text-black">From Date :</span>{" "}
                                {date && date?.length === 2
                                  ? date[0].toDateString()
                                  : ""}{" "}
                                - <span className="text-black">To Date: </span>
                                {date && date?.length === 2
                                  ? date[1].toDateString()
                                  : ""}
                              </div>
                              {isOpen && (
                                <div className="calanderSection">
                                  <Calendar
                                    onChange={setDate}
                                    value={date}
                                    selectRange={true} // Enable range selection
                                  />
                                  <div style={{ marginTop: "10px" }}>
                                    <button
                                      onClick={handleConfirm}
                                      disabled={!date}
                                    >
                                      Confirm
                                    </button>
                                    <button onClick={handleCancel}>
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              )}
                              {/*end::Label*/}
                            </div>
                            {/*end::Status*/}
                            {/*begin::Search*/}
                            {/*end::Search*/}
                          </div>
                          {/*begin::Filters*/}
                        </div>
                        {/*end::Actions*/}
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Card body*/}
                      <div className="card-body">
                        {/*begin::Table*/}
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-3"
                          id="kt_table_widget_5_table"
                        >
                          {/*begin::Table head*/}
                          <thead>
                            {/*begin::Table row*/}
                            <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-150px">Job name</th>
                              <th className="text-end pe-3 min-w-100px">
                                User Name
                              </th>
                              <th className="text-end pe-3 min-w-150px">
                                Date Added
                              </th>
                              <th className="text-end pe-3 min-w-100px">
                                Amount
                              </th>
                              <th className="text-end pe-3 min-w-100px">
                                Status
                              </th>
                              <th className="text-end pe-3 min-w-100px">
                                Action
                              </th>
                            </tr>
                            {/*end::Table row*/}
                          </thead>
                          {/*end::Table head*/}
                          {/*begin::Table body*/}
                          {allJobs?.length > 0
                            ? allJobs?.map((item) => {
                                console.log("item", item);

                                return (
                                  <tbody className="fw-bold text-gray-600">
                                    <tr>
                                      {/*begin::Item*/}
                                      <td>{item?.service?.title}</td>
                                      {/*end::Item*/}
                                      {/*begin::Product ID*/}
                                      <td className="text-end">
                                        {item?.user?.fullName}
                                      </td>
                                      {/*end::Product ID*/}
                                      {/*begin::Date added*/}
                                      <td className="text-end">
                                        {moment(item?.createdAt).format(
                                          "DD MMM, YYYY"
                                        )}
                                      </td>
                                      {/*end::Date added*/}
                                      {/*begin::Price*/}
                                      <td className="text-end">
                                        {}{" "}
                                        {item?.price
                                          ? currency + " " + item?.price
                                          : "--"}
                                      </td>
                                      {/*end::Price*/}
                                      {/*begin::Status*/}
                                      <td className="text-end">
                                        {item?.status == 1 ? (
                                          <span className="badge py-3 px-4 fs-7 badge-light-warning">
                                            Pending
                                          </span>
                                        ) : item?.status == 2 ? (
                                          <span className="badge py-3 px-4 fs-7 badge-light-info">
                                            In progress
                                          </span>
                                        ) : (
                                          <span className="badge py-3 px-4 fs-7 badge-light-success">
                                            Completed
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-end">
                                        <NavLink
                                          state={{ item, type: "user" }}
                                          to={RoutesPaths.JOB_DETAIL}
                                          className="btn btn-sm btn-primary"
                                        >
                                          View
                                        </NavLink>
                                      </td>
                                      {/*end::Status*/}
                                    </tr>
                                  </tbody>
                                );
                              })
                            : null}
                          {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                    {/*end::Table Widget 5*/}
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(0)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0zM7.354 1.646a.5.5 0 0 1 0 .708L2.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>

                    {generatePageNumbers().map((page, index) => (
                      <button
                        key={index}
                        className={`btn btn-light btn-active-light-primary mx-1 ${
                          page === currentPage ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                      >
                        {page + 1}
                      </button>
                    ))}

                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(totalPages - 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708zm4 0a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L13.293 7 8.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                  {/*end::Col*/}
                </div>
              </>
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
      {/*end:::Main*/}
    </>
  );
}
