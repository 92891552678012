import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getServiceProvidersAction,
  sendPaymentToProvider,
} from "../../Action/serviceProvider";
import { backendUrl } from "../../constants";
import useDebounce from "../../Services/debounse";
import { Controller, useForm } from "react-hook-form";
import { showToast } from "../../Action/toast";

export default function ServiceProvider() {
  const { serviceProviderList, count } = useSelector(
    (state) => state.serviceProvider
  );
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500); // 500ms debounce delay
  const recordsPerPage = 10;
  const totalPages = Math.ceil(count / recordsPerPage);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(
      getServiceProvidersAction(
        currentPage,
        recordsPerPage,
        debouncedSearchQuery
      )
    );
    // handleShowToast();
  }, [dispatch, currentPage, debouncedSearchQuery]);

  const handleShowToast = () => {
    dispatch(showToast("This is a success message!", "success"));
  };

  const toggleModal = (id) => {
    setShowPopup(!showPopup);
    setSelectedId(id);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // Adjust this to show more/less page numbers

    if (totalPages <= maxPageNumbers) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfPageNumbers = Math.floor(maxPageNumbers / 2);
      let startPage = Math.max(currentPage - halfPageNumbers, 0);
      let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages - 1);

      if (currentPage > totalPages - halfPageNumbers) {
        startPage = totalPages - maxPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };

  const handleClose = () => setShowPopup(false);

  const handleYes = (data) => {
    console.log("data", data);
    dispatch(sendPaymentToProvider(selectedId, data))
      .then(() => {
        toggleModal("");
        reset();
        dispatch(
          getServiceProvidersAction(
            currentPage,
            recordsPerPage,
            debouncedSearchQuery
          )
        );
      })
      .finally(() => {});
  };

  const AlertPopup = ({ show, handleClose, handleYes }) => {
    return (
      <div
        className={`modal modal-confirmation ${show ? "show" : ""}`}
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <h2>Send payment to provider</h2>
          <p>Are you sure you want to send the payment to provider?</p>
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: "Amount is required",
                  validate: (value) => {
                    if (value <= 0) return "Amount must be greater than 0";
                    if (value > 10000)
                      return "Amount must be less than or equal to 10000";
                    return true;
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="number"
                    className="form-control form-control-solid w-250px"
                    placeholder="Amount"
                  />
                )}
              />
              {errors.amount && (
                <p className="error withdrawerrortext">
                  {errors.amount.message}
                </p>
              )}
            </div>
            <div className="d-flex align-items-center position-relative my-1">
              <Controller
                name="notes"
                control={control}
                rules={{ required: "Notes are required" }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className="form-control form-control-solid w-250px"
                    placeholder="Notes"
                  />
                )}
              />
              {errors.notes && (
                <p className="error withdrawerrortext">
                  {errors.notes.message}
                </p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button className="secondary" onClick={handleClose}>
              Cancel
            </button>
            <button className="primary" onClick={handleSubmit(handleYes)}>
              Withdraw
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <AlertPopup
        show={showPopup}
        handleClose={handleClose}
        handleYes={handleYes}
      />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar pt-7 pt-lg-10">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-fluid d-flex align-items-stretch"
            >
              <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                    Service Providers
                  </h1>
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                    <li className="breadcrumb-item text-muted">
                      Over {count} new service providers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header border-0 pt-6">
                  <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5" />
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search Provider"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body py-4">
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5"
                    id="kt_table_users"
                  >
                    <thead>
                      <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-125px">Service Providers</th>
                        <th className="min-w-125px">Email Id</th>
                        <th className="min-w-125px">Contact No</th>
                        <th className="min-w-125px">Services</th>
                        <th className="min-w-125px">Rating</th>
                        <th className="min-w-125px">Balance</th>
                        <th className="text-end min-w-100px">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-semibold">
                      {serviceProviderList.length > 0 ? (
                        serviceProviderList.map((serviceProvider, index) => {
                          return (
                            <tr key={index}>
                              <td className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                  <div className="symbol-label">
                                    <img
                                      src={
                                        backendUrl + serviceProvider.profilePic
                                      }
                                      alt=""
                                      className="w-100"
                                      onError={handleError} // handle the error event
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  {serviceProvider.fullName}

                                  {/* <span>smith@kpmg.com</span> */}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <span className="text-gray-800 mb-1">
                                    {serviceProvider.email}
                                  </span>
                                  {/* <span>Paid</span> */}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <span className="text-gray-800 mb-1">
                                    {/* {serviceProvider.countryCode}{" "} */}
                                    +27
                                    {serviceProvider.mobileNumber}
                                  </span>
                                  {/* <span>IN</span> */}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <span className="text-gray-800 mb-1">
                                    {serviceProvider?.services?.length ?? "--"}
                                  </span>
                                  {/* <span>Web, UI/UX Design</span> */}
                                </div>
                              </td>
                              <td>
                                <div className="rating justify-content-start">
                                  {Array.from({ length: 5 }, (_, index) => {
                                    const ratingValue = index + 1;

                                    // Full star if the rating is greater than or equal to the index + 1
                                    if (
                                      serviceProvider?.avgRating >= ratingValue
                                    ) {
                                      return (
                                        <div
                                          key={index}
                                          className="rating-label checked"
                                        >
                                          <i className="ki-outline ki-star fs-6"></i>
                                        </div>
                                      );
                                    }
                                    // Half star if the rating is between the current index and the next one
                                    else if (
                                      serviceProvider?.avgRating >=
                                      ratingValue - 0.5
                                    ) {
                                      return (
                                        <div
                                          key={index}
                                          className="rating-label checked"
                                        >
                                          <i className="ki-outline ki-star-half fs-6"></i>{" "}
                                          {/* use a half-star icon */}
                                        </div>
                                      );
                                    }
                                    // Empty star for the rest
                                    return (
                                      <div key={index} className="rating-label">
                                        <i className="ki-outline ki-star fs-6"></i>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <span className="text-gray-800 mb-1">
                                    {/* {serviceProvider.countryCode}{" "} */}
                                    {serviceProvider.balance?.toFixed(2)}
                                  </span>
                                  {/* <span>IN</span> */}
                                </div>
                              </td>

                              <td className="text-end">
                                <NavLink
                                  to={RoutesPaths.OVERVIEW_SERVICE_PROVIDER}
                                  state={{ serviceProvider: serviceProvider }}
                                  className="btn btn-light btn-active-primary btn-light-primary btn-flex btn-center btn-sm me-2"
                                  data-kt-menu-trigger="click"
                                  data-kt-menu-placement="bottom-end"
                                >
                                  View
                                </NavLink>
                                <button
                                  className="btn btn-light btn-active-primary btn-secondary btn-flex btn-center btn-sm"
                                  data-kt-menu-trigger="click"
                                  data-kt-menu-placement="bottom-end"
                                  onClick={() => {
                                    toggleModal(serviceProvider?._id);
                                  }}
                                >
                                  Withdraw
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No service Provider available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(0)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0zM7.354 1.646a.5.5 0 0 1 0 .708L2.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>

                    {generatePageNumbers().map((page, index) => (
                      <button
                        key={index}
                        className={`btn btn-light btn-active-light-primary mx-1 ${
                          page === currentPage ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                      >
                        {page + 1}
                      </button>
                    ))}

                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(totalPages - 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708zm4 0a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L13.293 7 8.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
