import { GET_FILTER_REVENUE_TRACKING, GET_REVENUE_TRACKING } from "../Action/type";

const initialState = {
    revenueTrackingData: {},
    revenueFilterData: [],
};
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_REVENUE_TRACKING:
            return {
                ...state,
                revenueTrackingData: payload,
            };

        case GET_FILTER_REVENUE_TRACKING:
            return {
                ...state,
                revenueFilterData: payload,
            };

        default:
            return state;
    }
}