import { GET_JOB_DETAIL, GET_NOTIFICATION_SETTING, GET_PAST_JOBS, GET_SINGLE_USER, GET_TRANSACTION_HISTORY, GET_USER } from "../Action/type";

const initialState = {
  userList: [],
  count: 0,
  notificationSettingList: [],
  singleUserDetail: [],
  pastJobs: [],
  pastJobsCount: '',
  transactionHistory: [],
  transactionHistoryCount: '',
  singleJobDetail: {}
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        userList: payload?.users,
        count: payload?.count,
      };

    case GET_NOTIFICATION_SETTING:
      return {
        ...state,
        notificationSettingList: payload,
      };

    case GET_SINGLE_USER:
      return {
        ...state,
        singleUserDetail: payload,
      };

    case GET_PAST_JOBS:
      return {
        ...state,
        pastJobs: payload.jobs,
        pastJobsCount: payload.count
      };

    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: payload.transactions,
        transactionHistoryCount: payload.count
      };

    case GET_JOB_DETAIL:
      return {
        ...state,
        singleJobDetail: payload,
      };

    default:
      return state;
  }
}
