import questionService from "../Services/questionService";
import { showToast } from "./toast";
import { GET_BANNER, GET_QUESTION, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const addQuestionAction = (payload) => (dispatch) => {
    dispatch(startLoaderAction());
    return questionService.addQuestion(payload).then((resp) => {
        if (resp.status) {
            dispatch(showToast(resp?.data, "success"));
            dispatch(stopLoaderAction());
        } else {
            dispatch(showToast(resp?.data, "error"));
            dispatch(stopLoaderAction());
        }
        return Promise.resolve();
    });
};

export const getQuestionAction = (page, limit) => async (dispatch) => {
    return questionService.getQuestion(page, limit).then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_QUESTION, payload: resp.data });
        } else {
            //   dispatch(showToast(resp?.data, "error"));
        }
    });
};

export const editQuestionAction = (payload) => async (dispatch) => {
    dispatch(startLoaderAction());
    const resp = await questionService.editBanner(payload);
    if (resp.status) {
        dispatch(showToast(resp?.data, "success"));
        dispatch(stopLoaderAction());
    } else {
        dispatch(showToast(resp?.data, "error"));
        dispatch(stopLoaderAction());
    }
    return await Promise.resolve();
};
