import { GET_DASHBOARD_DATA, GET_DASHBOARD_REVENUE } from "../Action/type";

const initialState = {
    dashboardData: {},
    dashboardRevenueData: [],
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashboardData: payload,
            };

        case GET_DASHBOARD_REVENUE:
            return {
                ...state,
                dashboardRevenueData: payload,
            };

        default:
            return state;
    }
}
