import jobsServices from "../Services/jobsServices";
import { showToast } from "./toast";
import { GET_ALL_JOBS } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";


export const getAllJobs = (currentPage, recordsPerPage, status, date) => async (dispatch) => {
    dispatch(startLoaderAction())
    return jobsServices.getAllJobs(currentPage, recordsPerPage, status, date).then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_ALL_JOBS, payload: resp.data });
        } else {
            dispatch(showToast(resp?.data, "error"));
        }
        dispatch(stopLoaderAction())
    });
};
