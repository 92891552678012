import { combineReducers } from "redux";
import auth from "./auth";
import category from "./category";
import services from "./services";
import banner from "./banner";
import user from "./user";
import serviceProvider from "./serviceProvider";
import question from "./question";
import notification from "./notification";
import loaderReducer from "./loader";
import dashBoard from "./dashBoard";
import revenueTracking from "./revenueTracking";
import toastReducer from "./ToastReducer";
import jobs from "./jobs";

const rootReducer = combineReducers({
  auth,
  category,
  services,
  banner,
  user,
  serviceProvider,
  question,
  notification,
  loaderReducer,
  dashBoard,
  revenueTracking,
  toastReducer,
  jobs
});


export default rootReducer;
