import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCta85uKrKEdo8I4mF8HSCsEu7-0CVZvTE",
    authDomain: "express-jobs-4eb0c.firebaseapp.com",
    projectId: "express-jobs-4eb0c",
    storageBucket: "express-jobs-4eb0c.appspot.com",
    messagingSenderId: "347762895646",
    appId: "1:347762895646:web:f1185728c0def172b5f70f",
    measurementId: "G-MM5WCD94GW"
};

// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// }

export const signIn = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      console.log("Signed in:", userCredential.user);
    })
    .catch((error) => {
      console.error("Error signing in:", error);
    });
    // try {
    //     const userCredential = await auth().signInWithEmailAndPassword(email, password);
    //     return userCredential.user;
    // } catch (error) {
    //     alert('Login failed', error.message);
    //     console.log(error.message);
    //     throw error;
    // }
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);