import { GET_PAST_JOB_PROVIDER, GET_SERVICE_PROVIDERS, GET_SINGLE_PROVIDER, GET_TRANSACTION_HISTORY_PROVIDER } from "../Action/type";

const initialState = {
  serviceProviderList: [],
  count: 0,
  pastJobProvider: [],
  countPastJobProvider: '',
  singleProvider: {},
  transactionHistoryProvider: [],
  countTransactionHistoryProvider: ''

};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SERVICE_PROVIDERS:
      return {
        ...state,
        serviceProviderList: payload?.providers,
        count: payload?.count,
      };

    case GET_PAST_JOB_PROVIDER:
      return {
        ...state,
        pastJobProvider: payload?.jobs,
        countPastJobProvider: payload?.count,
      };

    case GET_SINGLE_PROVIDER:
      return {
        ...state,
        singleProvider: payload,
      };

    case GET_TRANSACTION_HISTORY_PROVIDER:
      return {
        ...state,
        transactionHistoryProvider: payload.transactions,
        countTransactionHistoryProvider: payload.count,
      };

    default:
      return state;
  }
}
