import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const initialState = {};  // Empty or minimal state, as nothing is saved

const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SUCCESS_MESSAGE':
            const { message, type } = action.payload;

            // Trigger the toast based on the type
            switch (type) {
                case 'success':
                    toast.success(message);
                    break;
                case 'error':
                    toast.error(message);
                    break;
                case 'info':
                    toast.info(message);
                    break;
                case 'warn':
                    toast.warn(message);
                    break;
                default:
                    toast(message);
            }

            // Return current state, no modifications needed
            return state;

        default:
            return state;
    }
};

export default toastReducer;
