import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { backendUrl } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadFileAction } from "../../Action/uploadFile";
import {
  getSingleProviderDetail,
  updateProviderProfile,
} from "../../Action/serviceProvider";
import { RoutesPaths } from "../../Router/constant";
// import { RoutesPaths } from "../../Router/constant";

export default function ServiceProviderManage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange", // Enable validation on change
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { editData } = location.state || {};

  const [qualificationProof, setQualificationProof] = useState(false);
  const [passportProof, setPassportProof] = useState(false);
  const [nonCriminalProof, setNonCriminalProof] = useState(false);

  useEffect(() => {
    reset(editData);
    setImagePreviewUrl(editData?.profilePic ?? "");
    setQualificationProof(editData?.qualificationProof);
    setPassportProof(editData?.passportProof);
    setNonCriminalProof(editData?.nonCriminalProof);
  }, []);

  const navigate = useNavigate();

  const handleImageChange = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            if (type == 1) {
              setQualificationProof(response?.data?.fileName);
            } else if (type == 2) {
              setNonCriminalProof(response?.data?.fileName);
            } else if (type == 3) {
              setPassportProof(response?.data?.fileName);
            } else {
              setImagePreviewUrl(response?.data?.fileName);
            }
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const onSubmit = (data) => {
    const formData = {
      fullName: data?.fullName,
      description: data?.description,
      title: data?.title,
      profilePic: imagePreviewUrl ?? data?.profilePic,
    };
    dispatch(updateProviderProfile(editData?._id, formData))
      .then((res) => {
        navigate(-1); // Go back to the previous page
      })
      .finally(() => {});
  };

  const updateDocument = () => {
    const formData = {
      nonCriminalProof: nonCriminalProof ?? "",
      passportProof: passportProof ?? "",
      qualificationProof: qualificationProof ?? "",
    };

    dispatch(updateProviderProfile(editData?._id, formData))
      .then((res) => {
        navigate(-1); // Go back to the previous page
      })
      .finally(() => {});
  };

  const handleRemoveFile = (type) => {
    if (type == 1) {
      setQualificationProof(""); // Clear the selected file
    } else if (type == 2) {
      setNonCriminalProof("");
    } else {
      setPassportProof("");
    }
  };

  return (
    <>
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title fs-3 fw-bold">
                    Update Service Provider
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Form*/}
                <form
                  id="kt_project_settings_form"
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/*begin::Card body*/}
                  <div className="card-body p-9">
                    {/*begin::Row*/}
                    <div className="row mb-5">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Avatar</div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        {/*begin::Image input*/}
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                        >
                          {/*begin::Preview existing avatar*/}
                          <div
                            className="image-input-wrapper w-125px h-125px bgi-position-center"
                            style={{
                              backgroundImage: `url("${
                                imagePreviewUrl
                                  ? `${backendUrl + imagePreviewUrl}`
                                  : "assets/media/svg/avatars/blank.svg"
                              }")`,
                            }}
                          />
                          {/*end::Preview existing avatar*/}
                          {/*begin::Label*/}
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                          >
                            <i className="ki-outline ki-pencil fs-7" />
                            {/*begin::Inputs*/}
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => handleImageChange(0, e)}
                            />
                            <input
                              type="hidden"
                              name="avatar"
                              {...register("avatar")}
                            />
                            {/*end::Inputs*/}
                          </label>
                          {/*end::Label*/}
                          {/*begin::Cancel*/}
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                          {/*end::Cancel*/}
                          {/*begin::Remove*/}
                          {imagePreviewUrl && (
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                              onClick={removeImage}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                          {/*end::Remove*/}
                        </div>
                        {/*end::Image input*/}
                        {/*begin::Hint*/}
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {errors.image && (
                          <div className="text-danger">
                            {errors.image.message}
                          </div>
                        )}
                        {/*end::Hint*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Full Name
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="fullName"
                          placeholder="Full name"
                          {...register("fullName")}
                        />
                      </div>
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Title</div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="title"
                          placeholder="title"
                          {...register("title")}
                        />
                      </div>
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Description
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="description"
                          placeholder="description"
                          {...register("description")}
                        />
                      </div>
                      {/*begin::Col*/}
                    </div>
                  </div>
                  {/*end::Card body*/}
                  {/*begin::Card footer*/}
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={() => navigate(-1)}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                    >
                      Save Changes
                    </button>
                  </div>
                  {/*end::Card footer*/}
                </form>
                {/*end:Form*/}
              </div>
              {/*end::Card*/}

              {/*begin::Card*/}
              <div className="card mt-20">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title fs-3 fw-bold">
                    Upload Supporting Documents
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Form*/}
                {/*begin::Card body*/}
                <div className="card-body p-9">
                  {/*begin::Row*/}
                  <div className="row mb-8">
                    {/*begin::Col*/}
                    <div className="col-xl-3 fs-5 fw-medium">
                      Qualification Proof
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-xl-9 fv-row">
                      {!qualificationProof ? (
                        <input
                          type="file"
                          className="form-control form-control-solid"
                          name="qualificationProof"
                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                          onChange={(e) => handleImageChange(1, e)} // Pass both type and event
                        />
                      ) : (
                        <div className="fs-7 fw-medium nameClass">
                          <strong>Selected File:</strong> {qualificationProof}
                          <button
                            onClick={() => handleRemoveFile(1)}
                            className="remove-btn"
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*end::Row*/}

                  {/*begin::Row*/}
                  <div className="row mb-8">
                    {/*begin::Col*/}
                    <div className="col-xl-3 fs-5 fw-medium">
                      Non Criminal Proof
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-xl-9 fv-row">
                      {!nonCriminalProof ? (
                        <input
                          type="file"
                          className="form-control form-control-solid"
                          name="nonCriminalProof"
                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                          onChange={(e) => handleImageChange(2, e)} // Pass both type and event
                        />
                      ) : (
                        <div className="fs-7 fw-medium nameClass">
                          <strong>Selected File:</strong> {nonCriminalProof}
                          <button
                            onClick={() => handleRemoveFile(2)}
                            className="remove-btn"
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*end::Row*/}

                  {/*begin::Row*/}
                  <div className="row mb-8">
                    {/*begin::Col*/}
                    <div className="col-xl-3 fs-5 fw-medium">
                      Passport Proof
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-xl-9 fv-row">
                      {!passportProof ? (
                        <input
                          type="file"
                          className="form-control form-control-solid"
                          name="passportProof"
                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                          onChange={(e) => handleImageChange(3, e)} // Pass both type and event
                        />
                      ) : (
                        <div className="fs-7 fw-medium nameClass">
                          <strong>Selected File:</strong> {passportProof}
                          <button
                            onClick={() => handleRemoveFile(3)}
                            className="remove-btn"
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*end::Row*/}
                </div>
                {/*end::Card body*/}
                {/*begin::Card footer*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={() => {
                      navigate(-1); // Go back to the previous page
                    }}
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="kt_project_settings_submit"
                    onClick={() => {
                      updateDocument();
                    }}
                  >
                    Upload
                  </button>
                </div>
                {/*end::Card footer*/}
                {/*end:Form*/}
              </div>
              {/*end::Card*/}

            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
      {/*end:::Main*/}
    </>
  );
}
{/*begin::Card*/}
<div className="card mt-20">
  {/*begin::Card header*/}
  <div className="card-header">
    {/*begin::Card title*/}
    <div className="card-title fs-3 fw-bold">
      Sign-in Credentials
    </div>
    {/*end::Card title*/}
  </div>
  {/*end::Card header*/}
  {/*begin::Form*/}
  <form id="kt_project_settings_form" className="form">
    {/*begin::Card body*/}
    <div className="card-body p-9">
      {/*begin::Row*/}
      <div className="row mb-8">
        {/*begin::Col*/}
        <div className="col-xl-3">
          <div className="fs-6 fw-semibold mt-2 mb-3">
            Login Email Id
          </div>
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-9 fv-row">
          <input
            type="text"
            className="form-control form-control-solid"
            name="name"
            defaultValue="Email Id"
          />
        </div>
      </div>
      {/*end::Row*/}
      {/*begin::Row*/}
      <div className="row mb-8">
        {/*begin::Col*/}
        <div className="col-xl-3">
          <div className="fs-6 fw-semibold mt-2 mb-3">
            Create Password
          </div>
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-9 fv-row">
          <input
            type="password"
            className="form-control form-control-solid"
            name="type"
            defaultValue="**************"
          />
        </div>
      </div>
      {/*end::Row*/}
      {/*begin::Row*/}
      <div className="row mb-8">
        {/*begin::Col*/}
        <div className="col-xl-3">
          <div className="fs-6 fw-semibold mt-2 mb-3">
            Confirm Password *
          </div>
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-xl-9 fv-row">
          <input
            type="password"
            className="form-control form-control-solid"
            name="type"
            defaultValue="**************"
          />
        </div>
        {/*begin::Col*/}
      </div>
    </div>
    {/*end::Card body*/}
    {/*begin::Card footer*/}
    <div className="card-footer d-flex justify-content-end py-6 px-9">
      <button
        type="reset"
        className="btn btn-light btn-active-light-primary me-2"
      >
        Discard
      </button>
      <button
        type="submit"
        className="btn btn-primary"
        id="kt_project_settings_submit"
      >
        Register
      </button>
    </div>
    {/*end::Card footer*/}
  </form>
  {/*end:Form*/}
</div>
{/*end::Card*/}

{/*begin::Card*/}
<div className="card mt-20">
  {/*begin::Card header*/}
  <div className="card-header">
    {/*begin::Card title*/}
    <div className="card-title fs-3 fw-bold">Sign-in Method</div>
    {/*end::Card title*/}
  </div>
  {/*end::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body py-4">
    {/*begin::Table*/}
    <table
      className="table align-middle table-row-dashed fs-6 gy-5"
      id="kt_table_users"
    >
      <tbody className="text-gray-600 fw-semibold">
        <tr>
          <td className="d-flex align-items-center">
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <span className="text-gray-800 text-hover-primary mb-1">
                Email Adress
              </span>
              <span>support@keenthemes.com</span>
            </div>
            {/*begin::User details*/}
          </td>

          <td className="text-end">
            <span
              className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              Change Email
            </span>
          </td>
        </tr>
        <tr>
          <td className="d-flex align-items-center">
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <span className="text-gray-800 text-hover-primary mb-1">
                Password
              </span>
              <span>****************</span>
            </div>
            {/*begin::User details*/}
          </td>
          <td className="text-end">
            <span
              className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              Reset Password
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    {/*end::Table*/}
  </div>
  {/*end::Card*/}
</div>
{/*end::Card*/}
