import axionIns from "../axios";

const addQuestion = async (payload) => {
    return axionIns
        .post("admin/quiz/questions", JSON.stringify(payload))
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("err", JSON.stringify(error.response.data));
            return error?.response?.data ? error?.response?.data : { status: false };
        });
};

const getQuestion = async (page, limit) => {
    return axionIns
        .get(`admin/quiz/questions?page=${page ?? ''}&limit=${limit ?? ''}`)
        .then((response) => {
            return response.data;
        });
};

const editBanner = async (payload) => {
    return axionIns
        .put("admin/quiz/questions/" + payload.id, JSON.stringify(payload))
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("err", JSON.stringify(error.response.data));
            return error?.response?.data ? error?.response?.data : { status: false };
        });
};

export default {
    addQuestion,
    getQuestion,
    editBanner,
};
