import { useLocation } from "react-router";
import { backendUrl, currency } from "../../constants";
import { useEffect, useRef, useState } from "react";
import {
  collection,
  Firestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getSingleJobDetail } from "../../Action/user";
import moment from "moment";
import Loader from "react-js-loader";
import {
  startLoaderAction,
  stopLoaderAction,
} from "../../Action/updateReducerValue";

export default function UserJobDetail() {
  const location = useLocation();
  const { item, type } = location.state || {};
  const { singleJobDetail } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const targetRef = useRef(null);

  const checkUser = singleJobDetail?.user?._id;
  // const chatId = singleJobDetail?.chat?.length ? singleJobDetail?.chat[0] : "";

  // Static chat data
  const initialMessages = [
    { text: "Hello!", sender: "You", time: "10:00 AM" },
    { text: "Hi there!", sender: "Bob", time: "10:01 AM" },
    { text: "How are you?", sender: "You", time: "10:02 AM" },
    { text: "I'm good, how about you?", sender: "Bob", time: "10:03 AM" },
    { text: "Hello!", sender: "You", time: "10:00 AM" },
    { text: "Hi there!", sender: "Bob", time: "10:01 AM" },
    { text: "How are you?", sender: "You", time: "10:02 AM" },
    { text: "I'm good, how about you?", sender: "Bob", time: "10:03 AM" },
    { text: "Hello!", sender: "You", time: "10:00 AM" },
    { text: "Hi there!", sender: "Bob", time: "10:01 AM" },
    { text: "How are you?", sender: "You", time: "10:02 AM" },
    { text: "I'm good, how about you?", sender: "Bob", time: "10:03 AM" },
  ];

  // Initialize messages with static data
  const [messages, setMessages] = useState(initialMessages);
  const [input, setInput] = useState("");
  const [chatId, setChatId] = useState("");

  const [message, setMessage] = useState("");
  const [chatMessages, setchatMessages] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Fetch messages and listen for new messages
  useEffect(() => {
    // static chat id 66ebfc145688946ce2e41d16 replace with chatId variable
    dispatch(startLoaderAction());
    const q = query(
      collection(db, "chats"),
      where("chatId", "==", chatId),
      orderBy("createdAt", "asc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setchatMessages(msgs);
      dispatch(stopLoaderAction());
    });
    return () => unsubscribe();
  }, [chatId]);

  useEffect(() => {
    dispatch(getSingleJobDetail(item?.id));
  }, []);

  useEffect(() => {
    if (singleJobDetail?.chat?.length > 0) {
      setChatId(singleJobDetail?.chat[0]?.id);
      const result = singleJobDetail?.chat?.filter(
        (item) => item.id === singleJobDetail?.chat[0]?.id
      );
      setFilteredData(result);
    }
  }, [singleJobDetail]);

  const handleError = (event) => {
    event.target.src = "assets/img/defaultImg.png"; // fallback image
  };

  const handleError1 = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };

  const showChat = (id) => {
    dispatch(startLoaderAction());
    setChatId(id);
    const result = singleJobDetail?.chat?.filter((item) => item.id === id);
    setFilteredData(result);
    dispatch(stopLoaderAction());
    handleScrollToContent();
  };

  const handleScrollToContent = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Navbar*/}
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="card mb-5 mb-xxl-8 col-12 shadow">
                      <div className="card-body pt-9 pb-6">
                        {/*begin::Details*/}
                        {/* <h2>User Detail</h2> */}
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                          {/*begin: Pic*/}
                          <div className="me-7 mb-4">
                            <div className="symbol symbol-100px symbol-lg-70px symbol-fixed position-relative">
                              <img
                                src={
                                  backendUrl + singleJobDetail?.user?.profilePic
                                }
                                className="object-fit-cover img-style"
                                alt=""
                                onError={handleError}
                              />
                              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
                            </div>
                          </div>
                          {/*end::Pic*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1">
                            {/*begin::Title*/}
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                              {/*begin::User*/}
                              <div className="d-flex flex-column">
                                {/*begin::Name*/}
                                <div className="d-flex align-items-center">
                                  <h3>{singleJobDetail?.user?.fullName}</h3>
                                </div>
                                {/*end::Name*/}
                                {/*begin::Info*/}
                                {singleJobDetail?.address && (
                                  <div className="d-flex flex-wrap fw-semibold fs-6 pe-2">
                                    <h6 className="d-flex align-items-center me-5 mb-2">
                                      <i className="ki-outline ki-geolocation fs-4 me-1" />
                                      {singleJobDetail?.address}
                                    </h6>
                                  </div>
                                )}
                                <div className="d-flex flex-wrap fw-semibold fs-6 pe-2">
                                  <h6 className="d-flex align-items-center mb-2">
                                    <i className="ki-outline ki-sms fs-4 me-1" />
                                    {singleJobDetail?.user?.email}
                                  </h6>
                                </div>
                                {/*end::Info*/}
                              </div>

                              {/*end::User*/}
                            </div>
                            {/*end::Title*/}
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::Details*/}
                        <div>
                          <h4>Provider Detail</h4>
                        </div>
                        <div className="container">
                          <div className="row mt-5">
                            {singleJobDetail?.chat?.map((item) => {
                              if (item?.provider) {
                                return (
                                  <div
                                    className="col-lg-3 col-md-6 col-sm-12 mb-4"
                                    key={item.id}
                                  >
                                    <div className="cards h-100 shadow-sm border-1 rounded-lg">
                                      <div className="card-body p-4">
                                        {/* Provider Detail */}
                                        <div className="d-flex flex-column align-items-center">
                                          {/* Profile Pic */}
                                          <div className="mb-3 position-relative profile-pic-wrapper">
                                            <div className="symbol symbol-100px symbol-lg-70px symbol-fixed position-relative  overflow-hidden shadow-sm border">
                                              <img
                                                src={
                                                  backendUrl +
                                                  item?.provider?.profilePic
                                                }
                                                className="object-fit-cover img-style"
                                                alt=""
                                                onError={handleError}
                                              />
                                              {/* <div classNames="position-absolute translate-middle bottom-0 start-100 mb-3 bg-success rounded-circle border border-2 border-white h-15px w-15px" /> */}
                                            </div>
                                          </div>
                                          {/* Info */}
                                          <div className="text-center">
                                            {/* Name */}
                                            {item?.provider?.fullName && (
                                              <h5 className="text-dark mb-1 font-weight-bold">
                                                {item?.provider?.fullName}
                                              </h5>
                                            )}
                                            {/* Address */}
                                            {item?.provider?.address && (
                                              <p className="text-muted small mb-1">
                                                <i className="ki-outline ki-geolocation me-1" />
                                                {item?.provider?.address}
                                              </p>
                                            )}
                                            {/* Email */}
                                            {item?.provider?.email && (
                                              <p className="text-muted small mb-2">
                                                <i className="ki-outline ki-sms me-1" />
                                                {item?.provider?.email}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                        <div className="text-center">
                                          <button
                                            className="view-chat-button px-4 py-2 mt-2"
                                            onClick={() => showChat(item?.id)}
                                          >
                                            View Chat
                                          </button>
                                        </div>
                                      </div>
                                      {/* Footer with Button */}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container mt-5">
                <h2 class="mb-4">Service Information</h2>
                <table class="table table-striped table-bordered">
                  <thead class="tableheading">
                    <tr className="headingClass">
                      <th scope="col">Provider</th>
                      <th scope="col">Service Name</th>
                      <th scope="col">Deadline</th>
                      <th scope="col">Price</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {singleJobDetail?.provider?.fullName ?? "-"}
                        {/* {singleJobDetail?.service?.image ? ( */}
                        {/* <img
                          src={backendUrl + singleJobDetail?.service?.image}
                          className="imgclass img-style"
                          alt=""
                          onError={handleError1}
                        /> */}
                        {/* ) : (
                          <img
                            src="assets/img/defaultImg.png"
                            className="imgclass"
                            alt=""
                            onError={handleError}
                          />
                        )} */}
                      </td>
                      <td>{singleJobDetail?.service?.title}</td>
                      <td>
                        {singleJobDetail?.deadline
                          ? moment(singleJobDetail?.deadline).format(
                              "YYYY-MM-DD"
                            )
                          : "-"}
                      </td>
                      <td>
                        {singleJobDetail?.price
                          ? currency + " " + singleJobDetail?.price
                          : "-"}
                      </td>
                      <td>
                        {singleJobDetail?.createdAt
                          ? moment(singleJobDetail?.createdAt).format(
                              "YYYY-MM-DD"
                            )
                          : "-"}
                      </td>
                      <td>
                        {singleJobDetail?.status == 1
                          ? "Pending"
                          : singleJobDetail?.status == 2
                          ? "In-Progress"
                          : singleJobDetail?.status == 3
                          ? "Success"
                          : "Closed"}
                      </td>
                    </tr>

                    {/* <!-- Add more rows as needed --> */}
                  </tbody>
                </table>
              </div>

              <div ref={targetRef} className="container card p-10 shadow mt-10">
                <h2>Chat Section</h2>
                <div className="chat-container">
                  <div className="chat-box">
                    <div className="chat-header">
                      <h5 className="text-white">
                        {type == "provider"
                          ? filteredData[0]?.user?.fullName
                          : filteredData[0]?.provider?.fullName}
                      </h5>
                    </div>
                    {chatMessages?.length > 0 ? (
                      <div className="chat-body">
                        {chatMessages.map((message, index) => {
                          let triggerbrValue = message?.message.split("-")[0]; // Extracts the part before '-'
                          if (triggerbrValue == "TRIGGERBR") {
                            const match = message.message.match(/-(.*)\./); // GET DATE
                            const amountValue = message.message.split(".")[1]; // GET AMMOUNT VALUE
                            return (
                              <div className="row justify-content-end">
                                <div
                                  className="card shadow-lg p-5 rounded-lg col-5"
                                  style={{ backgroundColor: "#f8f9fa" }}
                                >
                                  <div className="text-center mb-4">
                                    {/* <h2 className="text-success font-weight-bold mb-3">
                                    🎉 Congratulations!! 🎉
                                  </h2> */}
                                    <h4 className="text-muted">
                                      You sent a booking request to the service
                                      provider
                                    </h4>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-4 text-right">
                                      <h5 className="text-dark font-weight-semibold">
                                        Project Deadline
                                      </h5>
                                    </div>
                                    <div className="col-8">
                                      <h5 className="text-muted">
                                        : {match ? match[1].trim() : ""}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-4 text-right">
                                      <h5 className="text-dark font-weight-semibold">
                                        Budget Fixed
                                      </h5>
                                    </div>
                                    <div className="col-8">
                                      <h5 className="text-muted">
                                        : ZAR {amountValue}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (triggerbrValue == "TRIGGERBR_ACCEPT") {
                            return (
                              <div className="row justify-content-end mt-5">
                                <div
                                  className="card shadow-lg p-5 rounded-lg col-5"
                                  style={{ backgroundColor: "#f8f9fa" }}
                                >
                                  <div className="text-center mb-4">
                                    <h2 className="text-success font-weight-bold mb-3">
                                      🎉 Congratulations!! 🎉
                                    </h2>
                                    <h4 className="text-muted">
                                      You Booking request has been Accepted and
                                      your Project is in Progress.
                                    </h4>
                                  </div>

                                  {/* <div className="row mb-3">
                                  <div className="col-4 text-right">
                                    <h5 className="text-dark font-weight-semibold">
                                      Project Deadline
                                    </h5>
                                  </div>
                                  <div className="col-8">
                                    <h5 className="text-muted">
                                      : 25 Feb, 2023
                                    </h5>
                                  </div>
                                </div> */}

                                  {/* <div className="row">
                                  <div className="col-4 text-right">
                                    <h5 className="text-dark font-weight-semibold">
                                      Budget Fixed
                                    </h5>
                                  </div>
                                  <div className="col-8">
                                    <h5 className="text-muted">: ZAR 35,000</h5>
                                  </div>
                                </div> */}
                                </div>
                              </div>
                            );
                          } else if (triggerbrValue == "TRIGGERBR_REJECT") {
                            return (
                              <div className="row justify-content-end mt-5">
                                <div
                                  className="card shadow-lg p-5 rounded-lg col-5"
                                  style={{ backgroundColor: "#f8f9fa" }}
                                >
                                  <div className="text-center mb-4">
                                    <h2 className="text-success font-weight-bold mb-3">
                                      Bad news!
                                    </h2>
                                    <h4 className="text-muted">
                                      You Booking request has been Rejected
                                    </h4>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-4 text-right">
                                      <h5 className="text-dark font-weight-semibold">
                                        Project Deadline
                                      </h5>
                                    </div>
                                    <div className="col-8">
                                      <h5 className="text-muted">
                                        : 25 Feb, 2023
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-4 text-right">
                                      <h5 className="text-dark font-weight-semibold">
                                        Budget Fixed
                                      </h5>
                                    </div>
                                    <div className="col-8">
                                      <h5 className="text-muted">
                                        : ZAR 35,000
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (
                            triggerbrValue == "TRIGGERBR_PAYMENT_RELEASE"
                          ) {
                            const match = message.message.match(/-(.*)\./); // GET DATE
                            const amountValue = message.message.split(".")[1]; // GET AMMOUNT VALUE
                            return (
                              <div className="row mt-5">
                                <div
                                  className="card shadow-lg p-5 rounded-lg col-5"
                                  style={{ backgroundColor: "#f8f9fa" }}
                                >
                                  <div className="text-center mb-4">
                                    <h2 className="text-success font-weight-bold mb-3">
                                      Payment Release Request!
                                    </h2>
                                    <h4 className="text-muted">
                                      You Requested to Release the Payment
                                    </h4>
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-4 text-right">
                                      <h5 className="text-dark font-weight-semibold">
                                        Project Deadline
                                      </h5>
                                    </div>
                                    <div className="col-8">
                                      <h5 className="text-muted">
                                        : {match ? match[1].trim() : ""}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-4 text-right">
                                      <h5 className="text-dark font-weight-semibold">
                                        Budget Fixed
                                      </h5>
                                    </div>
                                    <div className="col-8">
                                      <h5 className="text-muted">
                                        : ZAR {amountValue}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (
                            triggerbrValue == "TRIGGERBR_PAYMENT_RELEASED"
                          ) {
                            return (
                              <div className="row mt-5 justify-content-end">
                                <div
                                  className="card shadow-lg p-5 rounded-lg col-5"
                                  style={{ backgroundColor: "#f8f9fa" }}
                                >
                                  <div className="text-center mb-4">
                                    <h2 className="text-success font-weight-bold mb-3">
                                      Funded!!
                                    </h2>
                                    <h4 className="text-muted">
                                      You have been funded with the Project fee
                                    </h4>
                                  </div>

                                  {/* <div className="row mb-3">
                                  <div className="col-4 text-right">
                                    <h5 className="text-dark font-weight-semibold">
                                      Project Deadline
                                    </h5>
                                  </div>
                                  <div className="col-8">
                                    <h5 className="text-muted">
                                      : 25 Feb, 2023
                                    </h5>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-4 text-right">
                                    <h5 className="text-dark font-weight-semibold">
                                      Budget Fixed
                                    </h5>
                                  </div>
                                  <div className="col-8">
                                    <h5 className="text-muted">: ZAR 35,000</h5>
                                  </div>
                                </div> */}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={index}
                                className={`chat-message ${
                                  message.sender === checkUser ? "self" : ""
                                  // checkUser
                                }`}
                              >
                                <div>
                                  <div>
                                    <strong>
                                      {message.sender === checkUser
                                        ? singleJobDetail?.user?.fullName
                                        : singleJobDetail?.provider?.fullName ??
                                          filteredData[0]?.provider?.fullName}
                                      :{" "}
                                    </strong>
                                    {message?.message}
                                    <div
                                      className={`chat-time ${
                                        message.sender === checkUser
                                          ? // "66d70401e4a7d1e7fa8e1fba"
                                            "chat-color"
                                          : ""
                                      }`}
                                    >
                                      {message?.createdAt
                                        .toDate()
                                        .toLocaleString()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <div
                        className="chat-body d-flex justify-content-center align-items-center"
                        style={{ height: "50vh" }}
                      >
                        <h4 className="">No chat</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div
                className="card shadow-sm p-5 rounded-lg"
                style={{
                  background: "linear-gradient(135deg, #ffffff, #f9f9f9)",
                  border: "1px solid #e0e0e0",
                  maxWidth: "600px",
                  margin: "auto",
                }}
              >
                <div className="text-center mb-4">
                  <h2 className="font-weight-bold" style={{ color: "#4caf50" }}>
                    🎉 Congratulations!! 🎉
                  </h2>
                  <p
                    className="lead"
                    style={{ color: "#555", marginTop: "10px" }}
                  >
                    You have been awarded with the project
                  </p>
                </div>

                <div className="row mb-3 align-items-center">
                  <div className="col-6 text-right">
                    <h5 className="font-weight-bold" style={{ color: "#333" }}>
                      Project Deadline
                    </h5>
                  </div>
                  <div className="col-6">
                    <h5 style={{ color: "#777" }}>25 Feb, 2023</h5>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-6 text-right">
                    <h5 className="font-weight-bold" style={{ color: "#333" }}>
                      Budget Fixed
                    </h5>
                  </div>
                  <div className="col-6">
                    <h5 style={{ color: "#777" }}>ZAR 35,000</h5>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/*end::Content container*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Content wrapper*/}
    </>
  );
}
