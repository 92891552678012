import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { backendUrl } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadFileAction } from "../../Action/uploadFile";
import {
  getSingleProviderDetail,
  updateProviderProfile,
} from "../../Action/serviceProvider";
import { RoutesPaths } from "../../Router/constant";
import { updateUserProfile } from "../../Action/user";
// import { RoutesPaths } from "../../Router/constant";

export default function UserEditProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange", // Enable validation on change
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { editData } = location.state || {};

  useEffect(() => {
    reset(editData);
    setImagePreviewUrl(editData?.profilePic ?? "");
  }, []);

  console.log("editData", editData);

  const navigate = useNavigate();

  const handleImageChange = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            setImagePreviewUrl(response?.data?.fileName);
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const onSubmit = (data) => {
    const formData = {
      fullName: data?.fullName,
      profilePic: imagePreviewUrl ?? data?.profilePic,
    };
    
    dispatch(updateUserProfile(editData?._id, formData))
      .then((res) => {
        navigate(-1); // Go back to the previous page
      })
      .finally(() => {});
  };

  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header">
                  <div className="card-title fs-3 fw-bold">
                    Update user profile
                  </div>
                </div>
                <form
                  id="kt_project_settings_form"
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="card-body p-9">
                    <div className="row mb-5">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Avatar</div>
                      </div>
                      <div className="col-lg-8">
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px bgi-position-center"
                            style={{
                              backgroundImage: `url("${
                                imagePreviewUrl
                                  ? `${backendUrl + imagePreviewUrl}`
                                  : "assets/media/svg/avatars/blank.svg"
                              }")`,
                            }}
                          />
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                          >
                            <i className="ki-outline ki-pencil fs-7" />
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => handleImageChange(0, e)}
                            />
                            <input
                              type="hidden"
                              name="avatar"
                              {...register("avatar")}
                            />
                          </label>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                          {imagePreviewUrl && (
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                              onClick={removeImage}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                        </div>
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {errors.image && (
                          <div className="text-danger">
                            {errors.image.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Full Name
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="fullName"
                          placeholder="Full name"
                          {...register("fullName")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={() => navigate(-1)}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
