export const SET_FAILED_MESSAGE = "SET_FAILED_MESSAGE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SERVICES = "GET_SERVICES";
export const GET_BANNER = "GET_BANNER";
export const GET_USER = "GET_USER";
export const GET_SERVICE_PROVIDERS = "GET_SERVICE_PROVIDERS";
export const GET_QUESTION = "GET_QUESTION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SETTING = "GET_NOTIFICATION_SETTING";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const GET_SINGLE_PROVIDER = "GET_SINGLE_PROVIDER";
export const GET_PAST_JOBS = "GET_PAST_JOBS";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_HISTORY_PROVIDER = "GET_TRANSACTION_HISTORY_PROVIDER";
export const GET_JOB_DETAIL = "GET_JOB_DETAIL";
export const GET_PAST_JOB_PROVIDER = "GET_PAST_JOB_PROVIDER";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_REVENUE = "GET_DASHBOARD_REVENUE";
export const GET_REVENUE_TRACKING = "GET_REVENUE_TRACKING";
export const GET_FILTER_REVENUE_TRACKING = "GET_FILTER_REVENUE_TRACKING";
export const GET_ALL_JOBS = "GET_ALL_JOBS";

