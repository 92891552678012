import moment from "moment";
import axionIns from "../axios";

const getUser = async (page, limit, search) => {
  return axionIns
    .get(`admin/users?page=${page}&limit=${limit}&search=${search}`)
    .then((response) => {
      return response.data;
    });
};

const getNotifiList = async (page, limit, search) => {
  return axionIns
    .get(`admin/notificationsetting`)
    .then((response) => {
      return response.data;
    });
};

const getSingleUser = async (id) => {
  return axionIns
    .get(`admin/users/${id}`)
    .then((response) => {
      return response.data;
    });
};

const getPastJobs = async (page, limit, status, id) => {
  return axionIns
    .get(`admin/users/jobs/${id}?status=${status}&page=${page}&limit=${limit}`)
    .then((response) => {
      return response.data;
    });
};

const getTransHistory = async (page, limit, date, status, type, id) => {
  return axionIns
    .get(`admin/users/transactions/${id}?fromDate=${date?.length ? moment(date[0]).format('YYYY-MM-DD') : ''}&toDate=${date?.length ? moment(date[1]).format('YYYY-MM-DD') : ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${type ?? ''}&status=${status ?? ''}`)
    .then((response) => {
      return response.data;
    });
};


const getJobDetail = async (id) => {
  return axionIns
    .get(`admin/jobs/${id}`)
    .then((response) => {
      return response.data;
    });
};

const deActivateUser = async (payload) => {
  return axionIns
    .put("admin/users/deactivate/" + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const activateUser = async (payload) => {
  return axionIns
    .put("admin/users/activate/" + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};


const updatePassword = async (id, payload) => {
  return axionIns
    .put("admin/users/updatepassword/" + id, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const updateProfile = async (id, payload) => {
  return axionIns
    .put("admin/users/" + id, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

export default {
  getUser,
  getNotifiList,
  getSingleUser,
  getPastJobs,
  getTransHistory,
  getJobDetail,

  deActivateUser,
  activateUser,
  updatePassword,
  updateProfile
};
