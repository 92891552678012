import notificationService from "../Services/notificationService";
import servicesServices from "../Services/servicesServices";
import { showToast } from "./toast";
import { GET_NOTIFICATION, GET_SERVICES, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const AddNotificationType = (payload) => (dispatch) => {
    dispatch(startLoaderAction());
    return notificationService.addNotification(payload).then((resp) => {
        if (resp.status) {
            dispatch(showToast(resp?.data, "success"));
            dispatch(stopLoaderAction());
        } else {
            dispatch(showToast(resp?.data, "error"));
            dispatch(stopLoaderAction());
        }
        return Promise.resolve();
    });
};

export const editNotificationType = (payload) => (dispatch) => {
    dispatch(startLoaderAction());
    return notificationService.editNotification(payload).then((resp) => {
        if (resp.status) {
            dispatch(showToast(resp?.data, "success"));
            dispatch(stopLoaderAction());
        } else {
            dispatch(showToast(resp?.data, "error"));
            dispatch(stopLoaderAction());
        }
        return Promise.resolve();
    });
};

export const deleteotificationType = (payload) => (dispatch) => {
    dispatch(startLoaderAction());
    return notificationService.deleteNotification(payload).then((resp) => {
        if (resp.status) {
            dispatch(showToast(resp?.data, "success"));
            dispatch(stopLoaderAction());
        } else {
            dispatch(showToast(resp?.data, "error"));
            dispatch(stopLoaderAction());
        }
        return Promise.resolve();
    });
};

export const getNotificationType = (page, limit, search) => async (dispatch) => {
    return notificationService.getNotification(page, limit, search).then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_NOTIFICATION, payload: resp.data });
        } else {
            //   dispatch(showToast(resp?.data, "error"));
        }
    });
};
