import dashBoardService from "../Services/dashBoardService";
import { GET_DASHBOARD_DATA, GET_DASHBOARD_REVENUE, SET_FAILED_MESSAGE } from "./type";

export const getDashboardData = () => async (dispatch) => {
    return dashBoardService.getDashboard().then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_DASHBOARD_DATA, payload: resp.data });
        } else {
            dispatch(showToast(resp?.data, "error"));
        }
    });
};
export const getDashboardRevenueData = (fromDate, toDate) => async (dispatch) => {
    return dashBoardService.getDashboardRevenue(fromDate, toDate).then((resp) => {
        if (resp.status) {
            dispatch({ type: GET_DASHBOARD_REVENUE, payload: resp.data });
        } else {
            dispatch(showToast(resp?.data, "error"));
        }
    });
};