import axionIns from "../axios";

const login = async (payload) => {
  return axionIns
    .post("admin/auth/signin", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("er", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const logOut = async (payload) => {
  return JSON.stringify(payload)
};
export default {
  login,
  logOut
};
